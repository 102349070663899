import React from "react";
import { Link } from "react-router-dom";

export default function Page404() {

  return (
      <section className="team sections-bg">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="row gy-4">
            <div
              className="aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="member" style={{margin: "100px 0"}}>
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <h6 className="mt-5">Go to The <Link to="/">Home Page</Link></h6>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
