import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function On_Demand_Delivery() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">Start capitalizing</p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                  On-Demand Delivery
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Empowering enterprises through the development of
                  customer-centric and value-driven on-demand solutions
                  <br />
                  that enhance sales performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container rSecStyle" data-aos="fade-up">
        <div className="row gy-4 wrapper">
          <div className="col-lg-9">
            <h1>Our On-Demand Delivery App Service Offering</h1>
            <article className="mt-4">
              <img
                src="/assets/images/solutions/food-delivery-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Food Delivery App: </a>
              </h2>
              <p>
                We specialize in crafting intelligent food delivery applications
                to support food establishments in implementing growth tactics,
                automating order processes, and optimizing delivery operations.
              </p>
              <img
                src="/assets/images/solutions/grocery-delivery-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Grocery Delivery App: </a>
              </h2>
              <p>
                Link Tech excels in the customization of grocery delivery apps,
                designed to provide grocers with heightened visibility and
                catalyze substantial business growth.
              </p>
              <img
                src="/assets/images/solutions/medicine-delivery-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Medicine Delivery App: </a>
              </h2>
              <p>
                Our team of developers constructs dependable and secure
                on-demand pharmaceutical delivery applications, incorporating
                user-friendly functionalities and diverse payment methods for
                seamless transactions.
              </p>
              <img
                src="/assets/images/solutions/education-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Education App: </a>
              </h2>
              <p>
                We implement state-of-the-art educational applications tailored
                to specific business requirements, delivering immersive and
                rewarding experiences.
              </p>
              <img
                src="/assets/images/solutions/doctor-on-demand.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Doctor On-Demand: </a>
              </h2>
              <p>
                Develop a highly interactive on-demand doctor's application that
                simplifies the process of scheduling consultations and
                appointments, while also providing valuable reports and data to
                enhance the user experience.
              </p>
              <img
                src="/assets/images/solutions/trucking-&-logistics-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Trucking & Logistics App: </a>
              </h2>
              <p>
                We specialize in developing premium logistics solutions to
                automate and streamline the day-to-day operations of your
                growing logistics enterprise seamlessly.
              </p>
            </article>
          </div>
          <aside className="col-lg-3">
          <div className="servSecImg">
            <img src="/assets/images/solutions/on-demand-delivery.jpg" alt="" />
          </div>
            <div className="footer-widget lineStyle mt-5">
              <div className="ltpColor mx-4">
                <h3>Solutions</h3>
              </div>
              <ul>
                <li>
                  <Link to="/solutions/healthcare-lifesciences">
                    Healthcare & Lifesciences
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/banking-finance-insurance">
                    Banking Finance & Insurance
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/e-commerce-solutions">
                    {" "}
                    E-Commerce Solutions{" "}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/solutions/on-demand-delivery">On Demand Delivery</Link>
                </li> */}
                <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li>
                <li>
                  <Link to="/solutions/transport-logistics">
                    Transport Logistics
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/education-eLearning">
                    Education & eLearning
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/enterprise-retail-manufacturing">
                    Enterprise Retail & Manufacturing
                  </Link>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What is an On-demand Delivery App?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    The on-demand delivery app is a digital platform that seamlessly connects customers with various services, offering instant access to essentials like medicine, groceries, and food through convenient door-to-door delivery or pick-up options. Examples of such apps include on-demand taxi-hailing services, online grocery delivery platforms, and convenient medication delivery services, all tailored to meet the evolving needs of today's consumers.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the benefits of an On-demand delivery app?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    The on-demand delivery platform streamlines the process for businesses by automating order placement, simplifying order management tasks, enabling real-time order tracking, and facilitating seamless delivery with convenient online payment collection. Additionally, features such as delivery alerts and offer notifications enhance customer engagement, while loyalty programs contribute to customer retention and drive increased sales.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the popular examples of On-demand delivery apps?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Today, we harness a variety of door-to-door delivery services through on-demand service apps, spanning diverse sectors like online medicine delivery, grocery and food delivery, alcohol delivery, courier services, and more. Additionally, common on-demand offerings encompass taxi-hailing apps, pick-and-drop services, fitness on-demand solutions, beauty services on-demand, home services apps, and a myriad of other convenient options tailored to modern lifestyles.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech{" "}
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
