// PHP Core, MySQL, Laravel. Salary Range - 50000 +
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function Cloud_services() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                  Reduced Risks. Enhanced Performance
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                  Cloud Computing Services
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Empowering enterprise agility, our Cloud Computing Services
                  offer flexibility, scalability, and cost-effectiveness, <br />
                  simplifying and optimizing your workflow for enhanced
                  efficiency and productivity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="container rSecStyle" data-aos="fade-up">
          <div className="row gy-4 wrapper">
            {/* <!-- End post list item --> */}
            <div className="col-lg-9">
              <h1>Our Featured Cloud Solutions</h1>
              <article className="mt-4">
              <div className="servSecImg">
                <img
                  className="img-fluid"
                  src="/assets/images/services/cloud-computing-services.jpg"
                  alt=""
                />
              </div>
                <img
                  src="/assets/images/services/cloud-implementation.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Cloud Implementation Strategy</a>
                </h2>
                <p>
                  From cloud advisory to comprehensive cloud management, we
                  serve as your end-to-end partner. Our expertise extends to
                  guiding businesses towards optimal cloud strategies, ensuring
                  robust security measures, and proficient cloud administration,
                  empowering seamless operations and growth.
                </p>
                <img
                  src="/assets/images/services/cloud-application.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Cloud Application Services</a>
                </h2>
                <p>
                  Link Tech Cloud Application services enable enterprises to
                  seamlessly migrate, manage, and monitor applications and
                  solutions on IaaS or PaaS cloud platforms, fostering
                  flexibility and efficiency to drive innovation and growth.
                </p>
                <img
                  src="/assets/images/services/cloud-management.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Cloud Management Services</a>
                </h2>
                <p>
                  Our cloud management services include forecast and trends
                  reporting, disaster recovery test reports, automation and
                  DevOps that help you to protect your cloud assets against any
                  downtimes.
                </p>
                <img
                  src="/assets/images/services/cloud-infrastructure.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Cloud Infrastructure & Implementation Services</a>
                </h2>
                <p>
                  From cloud infrastructure to Cloud data center design, build a
                  customized cloud that aligns to your business requirements.
                </p>
              </article>
            </div>
            <aside className="col-lg-3"
            >
              <div className="servSecImg">
              <img 
                src="/assets/images/services/cloud-computing-services-small.jpg"
                alt=""
              />
              </div>
              <div className="footer-widget lineStyle mt-5"
              >
                <div className="ltpColor mx-4">
                  <h3>Services</h3>
                </div>
                <ul
                >
                  <li>
                    <Link to="/services/app-development">
                      Mobile App Developemnt
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/web-development">Web Developemnt</Link>
                  </li>
                  <li>
                    <Link to="/services/software-development">
                      Software Developemnt
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/it-consulting">IT Consulting</Link>
                  </li>
                  <li>
                    <Link to="/services/digital-marketing">
                      Digital Marketing
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/services/cloud-services">Cloud Services</Link>
                  </li> */}
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                </ul>
              </div>
            </aside>
            {/* <!-- End post list item --> */}
          </div>
          {/* <!-- End recent posts list --> */}
        </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What is cloud computing?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      cloud computing is a delivery model that provides highly
                      scalable, on-demand access to computer resources,
                      including CPUs, storage, networking and other hosted
                      software services.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the benefits of cloud computing?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Cloud computing lowers IT operational costs because the
                      cloud provider manages the underlying infrastructure,
                      including hardware and software. Those managed components
                      are typically more reliable and secure than the standard
                      corporate data center. These advantages free IT teams to
                      focus on work that more directly benefits the business.{" "}
                      <br />
                      The cloud is also global, convenient, immensely scalable
                      and easily accessible, all of which accelerate the time to
                      create and deploy software applications. It opens
                      organizations to a host of newer services that enable the
                      most popular trends in application architectures and uses,
                      including microservices, containers, serverless computing,
                      machine learning, large-scale data analytics, IoT and
                      more.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How do I prepare my business for the cloud?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      It can take more than a year to execute a cloud migration
                      strategy, and even then, it's an ongoing process.
                      Communication is critical to success. Keep stakeholders
                      regularly informed, and make sure IT is part of the
                      broader decision-making process for the business. Identify
                      leaders who will evangelize the use of the cloud, and
                      ensure employees are properly trained for the transition.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-4"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How much does the cloud cost?
                    </button>
                  </h3>
                  <div
                    id="faq-content-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Public clouds charge on a per-use basis, so costs will
                      vary wildly based on multiple variables, including the
                      size of your environment, the provider, the region you
                      operate in, the amount of data movement and the number of
                      higher-level services consumed. <br />
                      The major public cloud providers also have pricing schemes
                      that can lower costs in exchange for certain long-term
                      commitments. <br />
                      <br />
                      There's considerable debate about whether the cloud is
                      ultimately cheaper or more expensive than traditional
                      on-premises computing -- but that misses the point. The
                      cloud can help modernize an enterprise and its IT
                      department and transition IT from its typical role as
                      gatekeeper into a key participant in the decision-making
                      process for the business side of an enterprise.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>                        
                        <div className="col-md-6">
                          <h5>By sending this form I agree to the Link Tech <Link to="/privacy-policy"> Privacy Policy</Link></h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
