import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Services() {
  const particle = React.useRef(null);

  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });
 
  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-10 text-center">
                <h2 data-aos="fade-up">Our Services</h2>
                <p data-aos="fade-up" data-aos-delay="400">
                Explore our comprehensive range of services designed to meet your unique business needs. As the premier IT software development firm, Link Tech possesses unparalleled expertise and profound industry knowledge to address a wide range of business challenges, from the straightforward to the intricate, through digital means.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services" className="section">
        <div className="top-icon-box position-relative">
          <div className="container position-relative">
            <div className="section-header">
              <h2>Services We Offer</h2>
              <p>At Link Tech, we specialize in turning your vision into exceptional, market-leading software, Website, Mobile app Development, Cloud& Infrastructure, Digital Marketing, and Design Engineering more products. Leveraging cutting-edge technologies and deep industry expertise we address your unique challenges ensuring a seamless and impactful journey towards digital excellence.</p>
            </div>
            <div className="row gy-4">
            <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <Link to="/services/app-development">
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/mobile-app-development.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Mobile App Development
                      </a>
                    </h4>
                    <p className="text-white">
                    Are you looking for a customized mobile app
                      development service? You're at the right place! Link Tech
                      Builds Mobile App for Every User, platform & Industry.
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Link to="/services/web-development">
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/web-development.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Web Development
                      </a>
                    </h4>
                    <p className="text-white">
                    Implementing customized website solutions centered around the customer's needs and focused on client satisfaction, we empower businesses to establish themselves as ...
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/software-development">
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/software-development.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Software Development
                      </a>
                    </h4>
                    <p className="text-white">
                    Delivering personalized software development solutions, meticulously crafted around your requirements and dedicated to ensuring client satisfaction, we empower businesses ...
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <Link to="/services/it-consulting">
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/it-consulting.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        IT Consulting
                      </a>
                    </h4>
                    <p className="text-white">
                      Craft innovative, future-proof, and scalable digital
                      solutions in partnership with a premier custom software
                      development company renowned for its expertise and
                      commitment to ...
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Link to="/services/digital-marketing">
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/digital-marketing.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Digital Marketing
                      </a>
                    </h4>
                    <p className="text-white">
                      Achieve remarkable outcomes with Link Tech's exceptional
                      digital strategy and top-tier digital marketing services,
                      propelling your business towards unparalleled success.
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/cloud-services.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Cloud Services
                      </a>
                    </h4>
                    <p className="text-white">
                      Empowering enterprise agility, our Cloud Computing
                      Services offer flexibility, scalability, and
                      cost-effectiveness, simplifying and optimizing your
                      workflow for enhanced ...
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container my-5" id="featured">
          <div
            className="section-header"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h2>Why Choose Us</h2>
            <p>
              Choose excellence choose turnkey IT services under one roof! We
              are your tech partner to boost your worldwide business operations.
            </p>
          </div>
          <div className="row">
            {/* <!-- start  left --> */}
            <div className="col-md-5 left">
              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="description">
                  <Link to="/why-choose-us">
                    <h4>
                      Expertise and
                      <span className="ltpColor"> Experience</span>
                    </h4>
                    <p>
                      In the realm of IT services, expertise and experience
                      stand as paramount pillars of distinction ...
                    </p>
                  </Link>
                </div>
                <div className="icon">
                  <img src="/assets/images/icons/icon-1.png" alt="icon" />
                  <small className="text-uppercase ltpColor">1</small>
                </div>
              </div>

              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="description">
                  <Link to="/why-choose-us">
                    <h4>
                      Quality<span className="ltpColor"> Products</span>
                    </h4>
                    <p>
                      When it comes to selecting an IT company, the quality of
                      products offered is non-negotiable ...
                    </p>
                  </Link>
                </div>
                <div className="icon">
                  <img src="/assets/images/icons/icon-2.png" alt="icon" />
                  <small className="text-uppercase ltpColor">2</small>
                </div>
              </div>
              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="description">
                  <Link to="/why-choose-us">
                    <h4>
                      Customer-Centric
                      <span className="ltpColor"> Approach</span>
                    </h4>
                    <p>
                      Choose us for our unwavering commitment to a
                      customer-centric approach. At our core ...
                    </p>
                  </Link>
                </div>
                <div className="icon">
                  <img src="/assets/images/icons/icon-3.png" alt="icon" />
                  <small className="text-uppercase ltpColor">3</small>
                </div>
              </div>
            </div>
            {/* <!-- end  left --> */}

            {/* <!-- start  center --> */}
            <div className="col-md-2 p-4 p-sm-5 center">
              <div
                className="list-center-wrap"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="center-icon">
                  <img src="/assets/images/icons/icon-center.png" alt="icon" />
                </div>
              </div>
            </div>
            {/* <!-- end  center --> */}
            {/* <!-- start  right --> */}
            <div className="col-md-5 right">
              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="icon">
                  <img src="/assets/images/icons/icon-4.png" alt="icon" />
                  <small className="text-uppercase ltpColor">4</small>
                </div>
                <div className="description">
                  <Link to="/why-choose-us">
                    <h4>
                      <span className="ltpColor">Competitive </span>
                      Pricing
                    </h4>
                    <p>
                      Select us for our competitive pricing model that ensures
                      maximum value for your investment ...
                    </p>
                  </Link>
                </div>
              </div>

              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="icon">
                  <img src="/assets/images/icons/icon-5.png" alt="icon" />
                  <small className="text-uppercase ltpColor">5</small>
                </div>
                <div className="description">
                  <Link to="/why-choose-us">
                    <h4>
                      <span className="ltpColor">Timely </span>Delivery
                    </h4>
                    <p>
                      Choose us for our unwavering dedication to timely
                      delivery. With a finely tuned workflow ...
                    </p>
                  </Link>
                </div>
              </div>

              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="icon">
                  <img src="/assets/images/icons/icon-6.png" alt="icon" />
                  <small className="text-uppercase ltpColor">6</small>
                </div>
                <div className="description">
                  <Link to="/why-choose-us">
                    <h4>
                      <span className="ltpColor">24/7 </span>Support
                    </h4>
                    <p>
                      Select us for our round-the-clock support, ensuring that
                      assistance is always available whenever ...
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- end  right --> */}
          </div>
        </div>
      </section>
    </div>
  );
}
