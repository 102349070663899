import React, { useEffect, useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { PortfolioData } from "../Data";
import { useCountUp } from "react-countup";

export default function Home() {
    const particle = React.useRef(null);

    React.useEffect(() => {
        // console.log(particle.current);
        window.particlesJS("particles-js", {
            particles: {
                number: {
                    value: 150,
                    density: {
                        enable: true,
                        value_area: 4000,
                    },
                },
                color: {
                    value: "#ffffff",
                },
                shape: {
                    type: "circle",
                    stroke: {
                        width: 0,
                        color: "#000000",
                    },
                    polygon: {
                        nb_sides: 5,
                    },
                    image: {
                        src: "img/github.svg",
                        width: 100,
                        height: 100,
                    },
                },
                opacity: {
                    value: 0.5,
                    random: false,
                    anim: {
                        enable: false,
                        speed: 1,
                        opacity_min: 0.1,
                        sync: false,
                    },
                },
                size: {
                    value: 6,
                    random: true,
                    anim: {
                        enable: false,
                        speed: 40,
                        size_min: 0.1,
                        sync: false,
                    },
                },
                line_linked: {
                    enable: true,
                    distance: 150,
                    color: "#ffffff",
                    opacity: 0.4,
                    width: 1,
                },
                move: {
                    enable: true,
                    speed: 6,
                    direction: "none",
                    random: false,
                    straight: false,
                    out_mode: "out",
                    bounce: false,
                    attract: {
                        enable: false,
                        rotateX: 600,
                        rotateY: 1200,
                    },
                },
            },
            interactivity: {
                detect_on: "canvas",
                events: {
                    onhover: {
                        enable: true,
                        mode: "grab",
                    },
                    onclick: {
                        enable: true,
                        mode: "push",
                    },
                    resize: true,
                },
                modes: {
                    grab: {
                        distance: 140,
                        line_linked: {
                            opacity: 1,
                        },
                    },
                    bubble: {
                        distance: 400,
                        size: 40,
                        duration: 2,
                        opacity: 8,
                        speed: 3,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                    push: {
                        particles_nb: 4,
                    },
                    remove: {
                        particles_nb: 2,
                    },
                },
            },
            retina_detect: true,
        });
    });
    const categories = [
        {
            category_id: "web_design",
            title: "Web Design",
        },
        {
            category_id: "web_development",
            title: "Web Development",
        },
        {
            category_id: "app_design",
            title: "App Design",
        },
        {
            category_id: "app_development",
            title: "App Development",
        },
        {
            category_id: "it_solutions",
            title: "It Solutions",
        },
    ];
    const [portSearch, setPortSearch] = useState(PortfolioData);
    // console.log(portSearch);

    const filterPortfolio = (category_id) => {
        if (category_id != "") {
            const updatePortfolios = PortfolioData.filter((curPortfolio) => {
                return curPortfolio.category_id === category_id;
            });
            setPortSearch(updatePortfolios);
        } else {
            setPortSearch(PortfolioData);
        }
    };

    //number counting
    useCountUp({
        ref: "counter",
        end: 7,
        enableScrollSpy: true,
        scrollSpyDelay: 100,
    });
    useCountUp({
        ref: "counter1",
        end: 5,
        enableScrollSpy: true,
        scrollSpyDelay: 100,
    });
    useCountUp({
        ref: "counter2",
        end: 5,
        enableScrollSpy: true,
        scrollSpyDelay: 100,
    });

    // When click url then show page top section
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <section id="hero" className="hero sticked-header-offset">
                <div ref={particle} id="particles-js"></div>
                <div className="container position-relative">
                    <div className="row gy-5 aos-init aos-animate">
                        <div className="col-lg-10 order-lg-1 d-flex flex-column justify-content-start text-left caption">
                            <p className="mb-2 text-uppercase">WORLD CLASS DIGITAL PRODUCTS AND SERVICES</p>
                            <h2 data-aos="fade-up" style={{ textTransform: "unset" }}>
                                Take an <span className="text-uppercase">Idea </span>
                                and Work on it <br />
                                <span style={{ fontSize: "35px", color: "white" }}>Elevate Your Business With Digital Solutions</span>
                                <span className="circle" data-aos="fade-right" data-aos-delay="800"></span>
                            </h2>
                            <p className="homeBannerCnt_description" data-aos="fade-up" data-aos-delay="400">
                                Work with the best team delivering excellence in <strong className="text-white">Mobile App</strong> and <strong className="text-white">Web Development</strong>.<br />
                                Experience a digital revolution in your business like never before.
                            </p>
                            <div className="d-flex justify-content-start">
                                <Link to="/contact" className="btn-get-started" data-aos="fade-up" data-aos-delay="1000">
                                    Talk to our experts
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Start Service Section --> */}
            <div id="services" className="section">
                <div className="top-icon-box position-relative">
                    <div className="container position-relative">
                        <div className="section-header">
                            <h2>Services We Offer</h2>
                            <p>
                                At Link Tech, we specialize in turning your vision into exceptional, market-leading software, Website, Mobile app Development, Cloud& Infrastructure, Digital Marketing, and Design
                                Engineering more products. Leveraging cutting-edge technologies and deep industry expertise we address your unique challenges ensuring a seamless and impactful journey towards digital
                                excellence.
                            </p>
                        </div>
                        <div className="row gy-4">
                            <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="100">
                                <Link to="/services/app-development">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img src="/assets/images/icons/mobile-app-development.png" alt="icon" />
                                        </div>
                                        <h4 className="title">
                                            <a href="" className="stretched-link">
                                                Mobile App Development
                                            </a>
                                        </h4>
                                        <p className="text-white">
                                            Are you looking for a customized mobile app development service? You're at the right place! Link Tech Builds Mobile App for Every User, platform & Industry.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--End Icon Box --> */}

                            <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="200">
                                <Link to="/services/web-development">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img src="/assets/images/icons/web-development.png" alt="icon" />
                                        </div>
                                        <h4 className="title">
                                            <a href="" className="stretched-link">
                                                Web Development
                                            </a>
                                        </h4>
                                        <p className="text-white">
                                            Implementing customized website solutions centered around the customer's needs and focused on client satisfaction, we empower businesses to establish themselves as ...
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--End Icon Box --> */}

                            <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                                <Link to="/services/software-development">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img src="/assets/images/icons/software-development.png" alt="icon" />
                                        </div>
                                        <h4 className="title">
                                            <a href="" className="stretched-link">
                                                Software Development
                                            </a>
                                        </h4>
                                        <p className="text-white">
                                            Delivering personalized software development solutions, meticulously crafted around your requirements and dedicated to ensuring client satisfaction, we empower businesses ...
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--End Icon Box --> */}

                            <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="100">
                                <Link to="/services/it-consulting">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img src="/assets/images/icons/it-consulting.png" alt="icon" />
                                        </div>
                                        <h4 className="title">
                                            <a href="" className="stretched-link">
                                                IT Consulting
                                            </a>
                                        </h4>
                                        <p className="text-white">
                                            Craft innovative, future-proof, and scalable digital solutions in partnership with a premier custom software development company renowned for its expertise and commitment to
                                            ...
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--End Icon Box --> */}

                            <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="200">
                                <Link to="/services/digital-marketing">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img src="/assets/images/icons/digital-marketing.png" alt="icon" />
                                        </div>
                                        <h4 className="title">
                                            <a href="" className="stretched-link">
                                                Digital Marketing
                                            </a>
                                        </h4>
                                        <p className="text-white">
                                            Achieve remarkable outcomes with Link Tech's exceptional digital strategy and top-tier digital marketing services, propelling your business towards unparalleled success.
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--End Icon Box --> */}

                            <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay="500">
                                <Link to="/services/cloud-services">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img src="/assets/images/icons/cloud-services.png" alt="icon" />
                                        </div>
                                        <h4 className="title">
                                            <a href="" className="stretched-link">
                                                Cloud Services
                                            </a>
                                        </h4>
                                        <p className="text-white">
                                            Empowering enterprise agility, our Cloud Computing Services offer flexibility, scalability, and cost-effectiveness, simplifying and optimizing your workflow for enhanced ...
                                        </p>
                                    </div>
                                </Link>
                            </div>
                            {/* <!--End Icon Box --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Service Section --> */}

            {/* <!-- Featured --> */}
            <section>
                <div className="container my-5" id="featured">
                    <div className="section-header" data-aos="fade-up" data-aos-delay="100">
                        <h2>Why Choose Us</h2>
                        <p>Choose excellence choose turnkey IT services under one roof! We are your tech partner to boost your worldwide business operations.</p>
                    </div>
                    <div className="row">
                        {/* <!-- start  left --> */}
                        <div className="col-md-5 left">
                            <div className="list-wrap" data-aos="fade-up" data-aos-delay="100">
                                <div className="description">
                                    <Link to="/why-choose-us">
                                        <h4>
                                            Expertise and
                                            <span className="ltpColor"> Experience</span>
                                        </h4>
                                        <p>In the realm of IT services, expertise and experience stand as paramount pillars of distinction ...</p>
                                    </Link>
                                </div>
                                <div className="icon">
                                    <img src="/assets/images/icons/icon-1.png" alt="icon" />
                                    <small className="text-uppercase ltpColor">1</small>
                                </div>
                            </div>

                            <div className="list-wrap" data-aos="fade-up" data-aos-delay="400">
                                <div className="description">
                                    <Link to="/why-choose-us">
                                        <h4>
                                            Quality<span className="ltpColor"> Products</span>
                                        </h4>
                                        <p>When it comes to selecting an IT company, the quality of products offered is non-negotiable ...</p>
                                    </Link>
                                </div>
                                <div className="icon">
                                    <img src="/assets/images/icons/icon-2.png" alt="icon" />
                                    <small className="text-uppercase ltpColor">2</small>
                                </div>
                            </div>
                            <div className="list-wrap" data-aos="fade-up" data-aos-delay="500">
                                <div className="description">
                                    <Link to="/why-choose-us">
                                        <h4>
                                            Customer-Centric
                                            <span className="ltpColor"> Approach</span>
                                        </h4>
                                        <p>Choose us for our unwavering commitment to a customer-centric approach. At our core ...</p>
                                    </Link>
                                </div>
                                <div className="icon">
                                    <img src="/assets/images/icons/icon-3.png" alt="icon" />
                                    <small className="text-uppercase ltpColor">3</small>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end  left --> */}

                        {/* <!-- start  center --> */}
                        <div className="col-md-2 p-4 p-sm-5 center">
                            <div className="list-center-wrap" data-aos="fade-up" data-aos-delay="100">
                                <div className="center-icon">
                                    <img src="/assets/images/icons/icon-center.png" alt="icon" />
                                </div>
                            </div>
                        </div>
                        {/* <!-- end  center --> */}
                        {/* <!-- start  right --> */}
                        <div className="col-md-5 right">
                            <div className="list-wrap" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon">
                                    <img src="/assets/images/icons/icon-4.png" alt="icon" />
                                    <small className="text-uppercase ltpColor">4</small>
                                </div>
                                <div className="description">
                                    <Link to="/why-choose-us">
                                        <h4>
                                            <span className="ltpColor">Competitive </span>
                                            Pricing
                                        </h4>
                                        <p>Select us for our competitive pricing model that ensures maximum value for your investment ...</p>
                                    </Link>
                                </div>
                            </div>

                            <div className="list-wrap" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon">
                                    <img src="/assets/images/icons/icon-5.png" alt="icon" />
                                    <small className="text-uppercase ltpColor">5</small>
                                </div>
                                <div className="description">
                                    <Link to="/why-choose-us">
                                        <h4>
                                            <span className="ltpColor">Timely </span>Delivery
                                        </h4>
                                        <p>Choose us for our unwavering dedication to timely delivery. With a finely tuned workflow ...</p>
                                    </Link>
                                </div>
                            </div>

                            <div className="list-wrap" data-aos="fade-up" data-aos-delay="500">
                                <div className="icon">
                                    <img src="/assets/images/icons/icon-6.png" alt="icon" />
                                    <small className="text-uppercase ltpColor">6</small>
                                </div>
                                <div className="description">
                                    <Link to="/why-choose-us">
                                        <h4>
                                            <span className="ltpColor">24/7 </span>Support
                                        </h4>
                                        <p>Select us for our round-the-clock support, ensuring that assistance is always available whenever ...</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end  right --> */}
                    </div>
                </div>
            </section>
            {/* <!-- Featured --> */}

            {/* <!-- - - - - - - - - Portfolio Section          --> */}
            <section id="portfolio" className="portfolio section-grey">
                <div className="container" data-aos="fade-up">
                    <div className="section-header">
                        <h2>Our Portfolio</h2>
                        <p>Discover Our Excellence in Design, Development, and IT Solutions</p>
                    </div>
                    <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order" data-aos="fade-up" data-aos-delay="100">
                        <div>
                            <ul className="portfolio-flters">
                                <NavLink onClick={() => filterPortfolio("")} to="">
                                  <li>All</li>
                                </NavLink>
                                <NavLink className='mx-4' onClick={() => filterPortfolio("web_design")} to="">
                                  <li>Web Design</li>
                                </NavLink>
                                <NavLink onClick={() => filterPortfolio("web_development")} to="">
                                  <li>Web Development</li>
                                </NavLink>
                                <NavLink className='mx-4' onClick={() => filterPortfolio("app_design")} to="">
                                  <li>App Design</li>
                                </NavLink>
                                <NavLink onClick={() => filterPortfolio("app_development")} to="">
                                  <li>App Development</li>
                                </NavLink>
                                <NavLink className='mx-4' onClick={() => filterPortfolio("itsolution")} to="">
                                  <li>It Solutions</li>
                                </NavLink>
                            </ul>
                            {/* <!-- End Portfolio Filters --> */}
                        </div>
                        <div className="row gy-4 portfolio-container">
                            {portSearch?.map((item) => (
                                <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                                    <div className="portfolio-wrap">
                                        <a target="blank" href={item.lg_image} data-gallery="portfolio-gallery-app" className="glightbox">
                                            <img
                                                src={item.image}
                                                // src="/assets/images/portfolio/app-small-1.jpg"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </a>
                                        <div className="portfolio-info">
                                            <h4>
                                                <a href="#" title="More Details">
                                                    {item.title}
                                                </a>
                                            </h4>
                                            <div className="project-deatils-aside">
                                                <div className="project-btn">
                                                    <a href={item.url} className="fill-btn">
                                                        View Project
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--  Testimonials Section  --> */}
            <section id="testimonials" className="testimonials">
                <div className="container" data-aos="fade-up">
                    <div className="section-header">
                        <h2>Testimonials</h2>
                        <p>Discover the voices of our satisfied clients, echoing the excellence of our services and the impact of our solutions.</p>
                    </div>
                    <div className="slides-3 swiper" data-aos="fade-up" data-aos-delay="800">
                        <div className="swiper-wrapper">
                            {/* <!-- Start testimonial item --> */}
                            <ReactOwlCarousel
                                className="owl-theme"
                                loop
                                autoplay={true}
                                responsive={{
                                    0: { items: 1 },
                                    768: { items: 2 },
                                }}
                            >
                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <div className="d-flex align-items-center info-box">
                                                <img src="/assets/images/testimonials/testimonial-1.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                <div>
                                                    <h3>Aqdam</h3>
                                                    {/* <h4>CFO</h4> */}
                                                    <div className="stars">
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                The professionalism and technical skills of the Link Tech team are commendable. They delivered our project on time and within budget
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <div className="d-flex align-items-center info-box">
                                                <img src="/assets/images/testimonials/testimonial-2.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                <div>
                                                    <h3>The mosque services</h3>
                                                    {/* <h4>Web Designer</h4> */}
                                                    <div className="stars">
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                Link Tech's software development services are top-notch. Their team is highly skilled, responsive, and easy to work with.
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <div className="d-flex align-items-center info-box">
                                                <img src="/assets/images/testimonials/testimonial-3.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                <div>
                                                    <h3>شركة برق</h3>
                                                    {/* <h4>Store Owner</h4> */}
                                                    <div className="stars">
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                Link Tech delivered a robust and scalable solution that met all our requirements. Their support and after-service are exceptional.
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <div className="d-flex align-items-center info-box">
                                                <img src="/assets/images/testimonials/testimonial-4.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                <div>
                                                    <h3>Al-Otaishan</h3>
                                                    {/* <h4>Freelancer</h4> */}
                                                    <div className="stars">
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                        <i className="bi bi-star-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                Link Tech's development team is outstanding. They delivered high-quality work and were always ready to go the extra mile.
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ReactOwlCarousel>
                            {/* <!-- End testimonial item --> */}
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
            </section>
            {/* <!-- End Testimonials Section --> */}

            {/* <!--  Start Counter Section  --> */}
            <div id="stats-counter" className="call-to-action stats-counter section">
                <div className="container" data-aos="fade-up">
                    <div className="row gy-4 align-items-center">
                        <div className="col-lg-4">
                            <div className="stats-item d-flex flex-column align-items-center">
                                <div className="icon">
                                    <img src="/assets/images/icons/happy-clients.png" alt="icon" />
                                </div>
                                <span id="counter"></span>
                                <p>
                                    <span>Happy Clients</span> Clients satisfaction is our first priority
                                </p>
                            </div>
                            {/* <!-- End Stats Item --> */}
                        </div>
                        <div className="col-lg-4">
                            <div className="stats-item d-flex flex-column align-items-center">
                                <div className="icon">
                                    <img src="/assets/images/icons/complete-projects.png" alt="icon" />
                                </div>
                                <span id="counter1"></span>
                                <p>
                                    <span>Completed Projects</span> Successfully delivered the projects on time
                                </p>
                            </div>
                            {/* <!-- End Stats Item --> */}
                        </div>
                        <div className="col-lg-4">
                            <div className="stats-item d-flex flex-column align-items-center">
                                <div className="icon">
                                    <img src="/assets/images/icons/hours-support.png" alt="icon" />
                                </div>
                                <span id="counter2"></span>
                                <p>
                                    <span>Running Projects</span> Discover excellence in development
                                </p>
                            </div>
                            {/* <!-- End Stats Item --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--  End Counter Section  --> */}

            {/* <!--  Clients Section  --> */}
            <div id="clients" className="clients section">
                <div className="container" data-aos="zoom-out">
                    <div className="clients-slider swiper" style={{ marginLeft: "30px" }}>
                        <div className="swiper-wrapper align-items-center owlDotsNone">
                            <ReactOwlCarousel
                                className="owl-theme"
                                loop
                                autoplay={true}
                                responsive={{
                                    0: { items: 1 },
                                    480: { items: 2 },
                                    700: { items: 3 },
                                    992: { items: 4 },
                                    1200: { items: 5 },
                                }}
                            >
                                <div className="swiper-slide customStyle">
                                    <img src="/assets/images/clients/client-logo-1.png" className="img-fluid" alt="" />
                                </div>
                                <div className="swiper-slide customStyle">
                                    <img src="/assets/images/clients/client-logo-2.png" className="img-fluid" alt="" />
                                </div>
                                <div className="swiper-slide customStyle">
                                    <img src="/assets/images/clients/client-logo-3.png" className="img-fluid" alt="" />
                                </div>
                                <div className="swiper-slide customStyle">
                                    <img src="/assets/images/clients/client-logo-4.png" className="img-fluid" alt="" />
                                </div>
                                <div className="swiper-slide customStyle">
                                    <img src="/assets/images/clients/client-logo-5.png" className="img-fluid" alt="" />
                                </div>
                            </ReactOwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Clients Section --> */}

            {/* <!--  Frequently Asked Questions Section  --> */}
            <section id="faq" className="faq">
                <div className="container" data-aos="fade-up">
                    <div className="section-header">
                        <h2>Link Tech FAQ's</h2>
                        <p>Below are some of the questions that may arise in your mind and their solutions</p>
                    </div>
                    <div className="row gy-4">
                        <div className="col-lg-12">
                            <div className="accordion accordion-flush" id="faqlist" data-aos="fade-up" data-aos-delay="100">
                                <div className="accordion-item">
                                    <h3 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                            <span className="num">
                                                <i className="bi bi-arrow-right-circle-fill"></i>
                                            </span>
                                            What Makes Link Tech Unique in Enterprise Software Development?
                                        </button>
                                    </h3>
                                    <div id="faq-content-1" className="accordion-collapse collapse show" data-bs-parent="#faqlist">
                                        <div className="accordion-body">
                                            At Link Tech, we stand out by combining business consulting, workflow automation, and data analytics to create an integrated dashboard, simplifying and enhancing your
                                            enterprise operations.
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- # Faq item--> */}

                                <div className="accordion-item">
                                    <h3 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                            <span className="num">
                                                <i className="bi bi-arrow-right-circle-fill"></i>
                                            </span>
                                            How Does Link Tech Handle Project Deadlines?
                                        </button>
                                    </h3>
                                    <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className="accordion-body">
                                            The efficient project deadline management at Link Tech comprises the following practices:
                                            <ul>
                                                <li>
                                                    <strong>Agile Practices for On-Time Delivery: </strong>
                                                    We adopt agile practices, ensuring increased visibility, flexibility and efficiency in meeting your deadlines.
                                                </li>
                                                <li>
                                                    <strong>Your Project, Your Ownership: </strong>
                                                    Experience complete control over your project's progress. We believe in empowering you with decision making, goal setting and full ownership.
                                                </li>
                                                <li>
                                                    <strong>Advanced Collaboration Tools: </strong>Utilize cutting-edge collaboration tools that streamline communication and coordination, keeping the project on track.
                                                </li>
                                                <li>
                                                    <strong>Scrum-Based Approach for Efficiency: </strong>
                                                    Our scrum-based methodology fosters quick adaptations and responsive changes, ensuring timely project completion.
                                                    <br />
                                                    By integrating these practices, we guarantee not just adherence to deadlines but also a seamless, client-driven project development experience, tailored to the dynamic
                                                    needs of enterprise-level operations.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- # Faq item--> */}

                                <div className="accordion-item">
                                    <h3 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                            <span className="num">
                                                <i className="bi bi-arrow-right-circle-fill"></i>
                                            </span>
                                            How Does Link Tech Stay Ahead with the Latest Technology Trends?
                                        </button>
                                    </h3>
                                    <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className="accordion-body">
                                            Link Tech not only stays abreast of the latest technology trends but also ensures that these advancements are effectively integrated into solutions that drive value for our
                                            enterprise clients. We implement following strategies:
                                            <ul>
                                                <li>
                                                    <strong>Center of Excellence (CoE): </strong>We have established a dedicated Center of Excellence focused on emerging technology trends & new frameworks. This hub is
                                                    our innovation powerhouse, continuously exploring and integrating new tech advancements.
                                                </li>
                                                <li>
                                                    <strong>Strategic Industry Partnerships: </strong>We collaborate with leading technology, solutions firms and academic institutions, gaining early access to
                                                    cutting-edge tools and methodologies.
                                                </li>
                                                <li>
                                                    <strong>Active R&D Investments: </strong>A significant portion of our resources is allocated to research and development, fostering innovation in our products and
                                                    services.
                                                </li>
                                                <li>
                                                    <strong>Regular Technology Audits: </strong>Our internal processes include frequent technology audits to assess and upgrade our tools and systems in line with the
                                                    latest industry standards.
                                                </li>
                                                <li>
                                                    <strong>Participation in Tech Conferences and Forums: </strong>
                                                    We actively participate in global tech conferences and forums, keeping our finger on the pulse of emerging trends and insights.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- # Faq item--> */}

                                <div className="accordion-item">
                                    <h3 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                            <span className="num">
                                                <i className="bi bi-arrow-right-circle-fill"></i>
                                            </span>
                                            What Is Your Process for Quality Assurance and Testing?
                                        </button>
                                    </h3>
                                    <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className="accordion-body">
                                            Here's how Link Tech ensures quality assurance and testing for delivering reliable and bug-free products:
                                            <ul>
                                                <li>
                                                    <strong>Quality Improvement Plans: </strong>Regularly revisiting and updating our QA strategies ensures continuous improvement in our processes and product quality.
                                                </li>
                                                <li>
                                                    <strong>Test-Driven Development (TDD): </strong>
                                                    Adopting iterative methodology when Quality is of utmost priority, employing TDD where tests are written before the code, ensuring that software development is guided
                                                    by specific quality requirements.
                                                </li>
                                                <li>
                                                    <strong>Dedicated QA Team Structure: </strong>Our well-defined QA team structure includes clear roles, responsibilities, and success measures, ensuring consistent
                                                    quality across projects.
                                                </li>
                                                <li>
                                                    <strong>Continuous Quality Control and Assurance: </strong>
                                                    We distinguish between quality control (QC), which examines the product or service for results, and quality assurance (QA), which examines and improves the processes
                                                    leading to the end-product. This holistic approach covers all aspects of quality, from planning to delivery.
                                                </li>
                                                <li>
                                                    <strong>Comprehensive Documentation Control: </strong>
                                                    We manage the distribution and archiving of documents, ensuring any changes are made through proper quality control procedures.
                                                </li>
                                                <li>
                                                    <strong>Automated Testing for critical Areas: </strong>
                                                    To enhance performance, security, third party integration's efficiency and accuracy, we apply automated testing to critical areas of the software.
                                                </li>
                                                <li>
                                                    <strong>Risk-Based Testing Framework: </strong>We use a risk-based approach to identify potential issues early in the development cycle and address them proactively.
                                                </li>
                                                <li>
                                                    <strong>Adherence to Quality Assurance Certifications: </strong>
                                                    We follow standards such as ISO 9000 and CMMI levels to ensure our QA mechanisms are mature and reliable.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- # Faq item--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Frequently Asked Questions Section --> */}

            {/* <!--  Call To Action Section  --> */}
            <section id="call-to-action" className="call-to-action">
                <div className="container text-center aos-init aos-animate" data-aos="zoom-out">
                    <div className="row gy-4">
                        <div className="col-lg-12">
                            <h3>Let's Discuss your Projects</h3>
                            <p>We pride ourselves with our ability to perform and deliver results. Use the form below to discuss your project needs with our team, we will get back asap</p>
                            <a className="cta-btn" href="mailto:info@example.com">
                                Conatct Us
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Call To Action Section --> */}

            {/* <!--  Recent Blog Posts Section  --> */}
            <section id="recent-posts" className="recent-posts sections-bg">
                <div className="container" data-aos="fade-up">
                    <div className="row gy-4">
                        <div className="col-lg-4">
                            <article>
                                <Link to="/blogs/crafting-a-car-service-center-application-guide">
                                    <div className="post-img">
                                        <img src="/assets/images/blog/Crafting-Car-Service.png" alt="" className="img-fluid" />
                                    </div>
                                    <p className="post-category mt-3">MOBILE APP DEVELOPMENT</p>
                                    <h2 className="title">Crafting a Car Service Center Application Guide.</h2>
                                </Link>
                            </article>
                        </div>
                        {/* <!-- End post list item --> */}

                        <div className="col-lg-4">
                            <article>
                                <Link to="/blogs/decide:-no-code-vs-custom-websites-development">
                                    <div className="post-img">
                                        <img src="/assets/images/blog/Custom-Websites-Development.jpg" alt="" className="img-fluid" />
                                    </div>
                                    <p className="post-category mt-3">WEB DEVELOPMENT</p>
                                    <h2 className="title">Decide: No-code vs Custom Websites Development.</h2>
                                </Link>
                            </article>
                        </div>
                        {/* <!-- End post list item --> */}

                        <div className="col-lg-4">
                            <article>
                                <Link to="/blogs/ai-revolutionizing-agriculture:-farming's-future-unveiled">
                                    <div className="post-img">
                                        <img src="/assets/images/blog/AI-Revolutionizing-Agriculture.jpg" alt="" className="img-fluid" />
                                    </div>
                                    <p className="post-category mt-3">ARTIFICIAL INTELLIGENCE</p>
                                    <h2 className="title">AI Revolutionizing Agriculture: Farming's Future Unveiled.</h2>
                                </Link>
                            </article>
                        </div>
                        {/* <!-- End post list item --> */}
                    </div>
                    {/* <!-- End recent posts list --> */}
                </div>
            </section>
            {/* <!-- End Recent Blog Posts Section --> */}

            {/* <!-- Start Contact Section --> */}
            <div id="contact" className="contact-section section">
                <div className="section-header">
                    <h2>Let's Talk</h2>
                    <p>Do you have a project in mind? Talk to the experts.</p>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12" data-aos="fade-right">
                            <div className="contact-information-box-3">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="single-contact-info-box">
                                            <div className="contact-info">
                                                <h6>Address:</h6>
                                                <p>Makkah Al Rusifa, Saudi Arabia</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="single-contact-info-box">
                                            <div className="contact-info">
                                                <h6>Phone:</h6>
                                                <p>+966 569777712</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="single-contact-info-box">
                                            <div className="contact-info">
                                                <h6>Email:</h6>
                                                <p>support@linktech.com.sa</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12" data-aos="fade-left">
                            <div className="contact-form-box contact-form contact-form-3">
                                <div className="form-container-box">
                                    <form className="contact-form form" id="ajax-contact" method="post" action="assets/phpscripts/contact.php">
                                        <div className="controls">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group form-input-box">
                                                        <input type="text" className="form-control" id="name" name="name" placeholder="Name*" required="required" data-error="Name is required." />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group form-input-box">
                                                        <input type="email" className="form-control" id="email" name="email" placeholder="Email*" required="required" data-error="Valid email is required." />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group form-input-box">
                                                        <input type="text" className="form-control" name="subject" placeholder="Subject" required="required" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group form-input-box">
                                                        <textarea
                                                            className="form-control"
                                                            id="message"
                                                            name="message"
                                                            rows="7"
                                                            placeholder="Write Your Message*"
                                                            required="required"
                                                            data-error="Please, leave us a message."
                                                        ></textarea>
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <button type="submit" data-text="Send Message">
                                                        Send Message
                                                    </button>
                                                </div>
                                                <div className="col-md-6">
                                                    <h5>
                                                        By sending this form I agree to the Link Tech
                                                        <Link to="/privacy-policy"> Privacy Policy</Link>
                                                    </h5>
                                                </div>
                                                <div className="messages">
                                                    <div className="alert alert alert-success alert-dismissable alert-dismissable hidden" id="msgSubmit">
                                                        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                                                            &times;
                                                        </button>{" "}
                                                        Thank You! your message has been sent.{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Contact Section --> */}
        </div>
    );
}
