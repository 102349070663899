import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function Transport_Logistics() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                ENHANCE OPERATIONAL PRODUCTIVITY
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                Transport & Logistics App Development
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Enhance overall productivity and drive success for your business with our top-tier
                  <br />
                  transport and logistics app management solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container rSecStyle" data-aos="fade-up">
        <div className="row gy-4 wrapper">
          <div className="col-lg-9">
            <h1>Our Transport & Logistics Development Service Offerings</h1>
            <article className="mt-4">
              <img
                src="/assets/images/solutions/taxi-app-development.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Taxi App Development: </a>
              </h2>
              <p>
              We specialize in developing dynamic and market-ready ride-sharing applications similar to Uber, capable of efficiently managing multiple requests simultaneously, thereby enhancing the overall user experience and service offerings.
              </p>
              <img
                src="/assets/images/solutions/trucking-logistics-app-development.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Trucking & Logistics App Development: </a>
              </h2>
              <p>
              Our team specializes in creating intelligent and innovative trucking and logistics management software, complete with advanced features designed to optimize business operations and enhance efficiency.
              </p>
              <img
                src="/assets/images/solutions/supply-chain-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Supply Chain Management: </a>
              </h2>
              <p>
              Link Tech provides tailor-made supply chain management solutions aimed at boosting profits, fostering transparency, streamlining operations and processes, and conserving time and resources.
              </p>
              <img
                src="/assets/images/solutions/on-demand-courier-delivery.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">On-Demand Courier Delivery: </a>
              </h2>
              <p>
              We specialize in developing cutting-edge end-to-end courier management and delivery solutions, providing customers with quick and convenient access to services while promoting seamless operational management.
              </p>
              <img
                src="/assets/images/solutions/warehouse-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Warehouse Management: </a>
              </h2>
              <p>
              At Link Tech, our proficient developers implement tailored warehouse management solutions designed to meet precise business requirements and facilitate real-time tracking of logistics.
              </p>
              <img
                src="/assets/images/solutions/fleet-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Fleet Management: </a>
              </h2>
              <p>
              We specialize in developing customized fleet management solutions that improve business performance and facilitate seamless management, including vehicle tracking, performance reporting, and more, based on specific requirements.
              </p>
            </article>
          </div>
          <aside className="col-lg-3">
          <div className="servSecImg">
            <img src="/assets/images/solutions/transport-&-logistics-app-development.jpg" alt="" />
          </div>
            <div className="footer-widget lineStyle mt-5">
              <div className="ltpColor mx-4">
                <h3>Solutions</h3>
              </div>
              <ul>
                <li>
                  <Link to="/solutions/banking-finance-insurance">
                    Banking Finance & Insurance
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/e-commerce-solutions">
                    E-Commerce Solutions
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/on-demand-delivery">
                    On Demand Delivery
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li>
                {/* <li>
                  <Link to="/solutions/transport-logistics">Transport Logistics</Link>
                </li> */}
                <li>
                  <Link to="/solutions/education-eLearning">
                    Education & eLearning
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/enterprise-retail-manufacturing">
                    Enterprise Retail & Manufacturing
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/travel-hospitality">
                    Travel & Hospitality
                  </Link>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the benefits of transport and logistics app development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    <strong>The transportation & logistics app development offers the following unique benefits to businesses, and are as follows;</strong>
                    <ol>
                      <li>Streamlined operations through automation.</li>
                      <li>Simplified asset tracking for effortless management.</li>
                      <li>Real-time vehicle monitoring and management capabilities.</li>
                      <li>Heightened security measures for comprehensive protection.</li>
                      <li>Intelligent inventory tracking for optimized stock management.</li>
                      <li>Efficient warehouse operations for improved logistics.</li>
                      <li>Prevention of stock-out situations through effective inventory management.</li>
                    </ol>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Does my transportation & logistics venture need a digital solution?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    In today's fast-paced business landscape, embracing digitalization and creating a bespoke transportation and logistics app is essential for achieving rapid growth and expanding your business footprint, ensuring you remain at the forefront of industry innovation and meet the evolving needs of your customers effectively.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How can you monetize your transport & logistics app development solution?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <strong>There are multiple ways through which you can monetize your transport and logistics solution, and are as follows;</strong>
                      <ul>
                        <li>Compensation</li>
                        <li>Promotions</li>
                        <li>Exclusive Plan</li>
                        <li>Paid Software</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech{" "}
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
