import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Terms_Conditions() {
  const particle = React.useRef(null);

  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-10 text-center">
                <h2 data-aos="fade-up">Terms & Conditions</h2>
                <p data-aos="fade-up" data-aos-delay="400">
                  By accessing and using the products and services offered by
                  Link Tech, you acknowledge that you comprehend and consider
                  these Terms and Conditions as the legal equivalent of a
                  signed, written contract, thereby. ......
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="recent-posts" className="sections-bg">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            {/* <!-- End post list item --> */}
            <div className="col-lg-8">
              <article>
                <h1>Link Tech Company Terms & Conditions: </h1>
                <p>
                  By accessing and using the products and services offered by
                  Link Tech, you acknowledge that you comprehend and consider
                  these Terms and Conditions as the legal equivalent of a
                  signed, written contract, thereby binding yourself to them.
                  Your acceptance of these Terms and Conditions signifies your
                  agreement to be legally bound by them. If you disagree with
                  the Terms and Conditions, you are not authorized to use the
                  Service or Products and should exit immediately. <br />
                  <br />
                  <strong>Interactions with our products: </strong>
                  Link Tech appreciates your feedback and suggestions for
                  enhancing our products and services. By submitting any
                  suggestions, information, materials, or other content to Link
                  Development, you grant Link Development a royalty-free,
                  perpetual, irrevocable, non-exclusive license to utilize,
                  reproduce, modify, adapt, publish, translate, create
                  derivative works from, distribute, redistribute, transmit,
                  perform, and display such content (in whole or part) worldwide
                  and/or incorporate it into other works in any form, media, or
                  technology, now known or developed later, for the entire
                  duration of any rights existing in such content. <br/>
                  Additionally,
                  Link Tech is entitled to utilize any ideas, concepts,
                  know-how, techniques, and suggestions conveyed in any
                  communications sent to this Service for any purpose, including
                  but not limited to the creation and marketing of products
                  and/or services utilizing such information. Privacy Statement.
                  Link Tech has a Privacy Statement that outlines the
                  information we collect about visitors, how we utilize such
                  information, and the measures we implement to safeguard it. We
                  advise reviewing it prior to utilizing our Product/Service.{" "}
                  <br />
                  <br />
                  <strong>Corrections and Changes: </strong>
                  Although we strive to maintain the
                  accuracy and currency of these materials, Link Tech cannot be
                  held liable for any errors or omissions contained therein.
                  Additionally, Link Tech does not guarantee the accuracy or
                  completeness of the information, text, graphics, links, or
                  other items within these materials. Link Tech reserves the
                  right to modify these materials, or the products or services
                  described herein, at any time without prior notice, and
                  assumes no obligation to update the information provided
                  herein. <br />
                  <br />
                  <strong>Indemnification: </strong>
                  You agree to defend, indemnify, and hold
                  harmless Link Tech, its affiliates, subsidiaries, and all of
                  their respective directors, officers, employees,
                  representatives, proprietors, partners, shareholders,
                  servants, principals, agents, predecessors, successors,
                  assigns, and attorneys from and against any and all claims,
                  proceedings, damages, injuries, liabilities, losses, costs,
                  and expenses (including attorney's fees and litigation
                  expenses) arising from or related to your use of the Product
                  and any breach of these Terms and Conditions by you. <br />
                  <br />
                  <strong>Use of the Internet: </strong>
                  The utilization of the Internet is solely
                  at your own risk and is governed by all applicable provincial,
                  national, and international laws and regulations. Link Tech
                  and its affiliates shall not be held responsible for any
                  losses arising from factors beyond their direct control,
                  including but not limited to electronic or mechanical
                  equipment failure, communication line issues, computer
                  viruses, unauthorized access, theft, operator errors, severe
                  weather, earthquakes, natural disasters, labor disputes, wars,
                  or governmental restrictions. <br />
                  <br />
                  These Terms and Conditions constitute the entire agreement
                  between you and Link Tech with respect to your use of the
                  Product/Service. You acknowledge that, in providing you access
                  to and use of the Product/Service, Link Tech has relied on
                  your agreement to be legally bound by these Terms and
                  Conditions. <br />
                  <br />
                  Contact Information Link Tech welcomes your comments regarding
                  this Terms of Use, please contact us by e-mail or contact us
                  section.
                </p>
              </article>
            </div>
            <div style={{ paddingLeft: "40px" }} className="col-lg-4">
              <div
                style={{ borderLeft: "5px solid #f2c76e", paddingLeft: "10px" }}
                className="footer-widget"
              >
                <div className="ltpColor mx-4">
                  <h3>Terms & Conditions</h3>
                </div>
                <ul
                  className="list"
                  style={{ borderLeft: "5px solid #f2c76e" }}
                >
                  <li>
                    <Link to="/privacy-policy">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- End post list item --> */}
          </div>
          {/* <!-- End recent posts list --> */}
        </div>
      </section>
    </div>
  );
}
