import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function Education_ELearning() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                TRANSFORMING EDUCATION
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                Education & eLearning Software Development
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  At Link Tech, we are dedicated to helping you turn your educational app development vision into reality,
                  <br />
                  utilizing the latest technologies and inventive learning approaches to launch your project successfully.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container rSecStyle"
        data-aos="fade-up"
      >
        <div className="row gy-4 wrapper">
          <div className="col-lg-9">
            <h1>
            Our Education & eLearning Service Offerings
            </h1>
            <article className="mt-4">
              <img
                src="/assets/images/solutions/m-learning-app-development.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">M-Learning App Development: </a>
              </h2>
              <p>
              We specialize in creating highly interactive and engaging mobile learning (M-Learning) applications that make the learning process enjoyable, accessible, and convenient for users to access quickly.
              </p>
              <img
                src="/assets/images/solutions/custom-e-learning-web-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Custom E-Learning Web App: </a>
              </h2>
              <p>
              Our team of education and eLearning solution development experts specializes in constructing responsive and customized eLearning web applications tailored to meet your specific requirements.
              </p>
              <img
                src="/assets/images/solutions/learning-management-system.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Learning Management System (LMS): </a>
              </h2>
              <p>
              Our experienced education app developers are capable of partnering with you to develop, manage, and deliver eLearning courses effectively through our versatile and robust Learning Management System (LMS) software.
              </p>
              <img
                src="/assets/images/solutions/content-management-system.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Content Management System (CMS): </a>
              </h2>
              <p>
              At Link Tech, we implement an exceptionally user-friendly CMS system crafted to effectively manage, create, edit, and publish webpage content.
              </p>
              <img
                src="/assets/images/solutions/online-course-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Online Course Management: </a>
              </h2>
              <p>
              We are experts in creating fully customizable online course management solutions that cater to the unique needs of educational institutions and non-profit organizations.
              </p>
              <img
                src="/assets/images/solutions/ar-vr-based-education-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">AR/VR Based Education App: </a>
              </h2>
              <p>
              We provide engaging and interactive learning experiences through eLearning applications enhanced with Augmented Reality (AR) and Virtual Reality (VR) technologies, ideal for immersive classroom experiences.
              </p>
              <img
                src="/assets/images/solutions/ai-based-education-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">AI-Based Education App: </a>
              </h2>
              <p>
              Create personalized learning applications for students and tailored training materials for employees, taking into account their individual abilities, learning preferences, and level of experience.
              </p>
              <img
                src="/assets/images/solutions/on-demand-learning-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">On-Demand Learning App: </a>
              </h2>
              <p>
              We specialize in creating tailored online video streaming, 3D book reading, and on-demand video learning applications, complemented by a robust admin panel and comprehensive analytics features.
              </p>
              <img
                src="/assets/images/solutions/education-erp-software.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Education ERP Software: </a>
              </h2>
              <p>
              Receive a fully integrated ERP suite specifically designed to oversee learning management, library administration, hostel management, fee management, student information tracking, course management, inventory and procurement control, and HR management.
              </p>
            </article>
          </div>
          <aside
            className="col-lg-3"
          >
            <div className="servSecImg">
            <img
              src="/assets/images/solutions/education-&-elearning-software-development.jpg"
              alt=""
            />
            </div>
            <div
              className="footer-widget lineStyle mt-5"
            >
              <div className="ltpColor mx-4">
                <h3>Solutions</h3>
              </div>
              <ul>
                <li>
                  <Link to="/solutions/e-commerce-solutions"> E-Commerce Solutions </Link>
                </li>
                <li>
                  <Link to="/solutions/on-demand-delivery">On Demand Delivery</Link>
                </li>
                <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li>
                <li>
                  <Link to="/solutions/transport-logistics">Transport Logistics</Link>
                </li>
                {/* <li>
                  <Link to="/solutions/education-eLearning">Education & eLearning</Link>
                </li> */}
                  <li>
                    <Link to="/solutions/enterprise-retail-manufacturing">Enterprise Retail & Manufacturing</Link>
                  </li>
                  <li>
                    <Link to="/solutions/travel-hospitality">Travel & Hospitality</Link>
                  </li>
                  <li>
                    <Link to="/solutions/media-entertainment">Media & Entertainment</Link>
                  </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What is an education & eLearning app?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    eLearning applications are meticulously crafted to facilitate skill development and training through features like live sessions, virtual classrooms, video conferencing, and online assessments, empowering learners with accessible and affordable avenues for education.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Do you develop eLearning apps in multiple languages?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Our eLearning apps are tailored to accommodate diverse linguistic needs, with the flexibility to integrate any language of your choice. With a rich portfolio showcasing our expertise in creating multilingual education and Learning applications, we ensure seamless accessibility for learners worldwide.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Can you help me with education app promotion and marketing?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    We offer comprehensive education and eLearning app development services, complemented by strategic planning, online marketing initiatives, and advertising campaigns tailored to expand your reach, engage your target audience, and drive revenue growth effectively.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech{" "}
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
