import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function E_Commerce_Solutions() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                Achieve a conversion increase of 10 times!
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                E-Commerce Product Development
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  By understanding the unique needs of businesses, we transform their vision into reality using the latest technologies,
                  <br />
                  delivering a market-ready eCommerce solution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container rSecStyle"
        data-aos="fade-up"
      >
        <div className="row gy-4 wrapper">
          <div className="col-lg-9">
            <h1>
            Our E-Commerce Product Development Services
            </h1>
            <article className="mt-4">
              <img
                src="/assets/images/solutions/ecommerce-online.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">E-Commerce Online Platform: </a>
              </h2>
              <p>
              At Link Tech, we specialize in delivering cutting-edge, scalable solutions for both B2B and B2C e-commerce platforms, utilizing state-of-the-art technology stacks, customized features, and leading e-commerce platforms to ensure seamless operations and optimal performance.
              </p>
              <img
                src="/assets/images/solutions/e-commerce-mobile-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">E-Commerce Mobile App:</a>
              </h2>
              <p>
              Our proficient e-commerce team excels in crafting omnichannel online stores, ensuring seamless shopping experiences anytime, anywhere, thus driving sales and bolstering brand awareness.
              </p>
              <img
                src="/assets/images/solutions/B2B-B2C-P2P-B2B2C-solution.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">B2B/B2C/P2P/B2B2C Solution: </a>
              </h2>
              <p>
              At Link Tech, we specialize in constructing robust, forward-looking platforms for B2B, B2C, P2P, and B2B2C enterprises, facilitating swift transactions and fostering seamless connections between buyers and sellers within a secure environment. 
              </p>
              <img
                src="/assets/images/solutions/e-commerce-enterprise-software.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">E-Commerce Enterprise Software: </a>
              </h2>
              <p>
              The skilled professionals at Link Tech are capable of designing and developing SEO-friendly custom enterprise portals, facilitating smooth business management and providing users with a seamless experience.
              </p>
              <img
                src="/assets/images/solutions/inventory-&-order-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Inventory & Order Management: </a>
              </h2>
              <p>
              Our tailored database development solutions are adaptable to your budget, scalability requirements, and future growth strategies.
              </p>
              <img
                src="/assets/images/solutions/affiliate-&-tracking.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Affiliate & Ad Tracking: </a>
              </h2>
              <p>
              Effectively monitor and analyze sales, conversions, and clicks originating from affiliate websites to gauge their performance, while also tracking online advertising campaigns and overall brand performance.
              </p>
              <img
                src="/assets/images/solutions/banner-mgmt-&-multi-shipping.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Banner Mgmt. & Multi-Shipping: </a>
              </h2>
              <p>
              With our proficient IT team, we seamlessly integrate relevant modules and plugins into your online store, empowering you to efficiently manage banners, multiple shipping options, and analyze their impact and effectiveness.
              </p>
            </article>
          </div>
          <aside
            className="col-lg-3"
          >
            <div className="servSecImg">
            <img
              src="/assets/images/solutions/e-commerce-product-development.jpg"
              alt=""
            />
            </div>
            <div
              className="footer-widget lineStyle mt-5"
            >
              <div className="ltpColor mx-4">
                <h3>Solutions</h3>
              </div>
              <ul>
                <li>
                  <Link to="/solutions/healthcare-lifesciences">Healthcare & Lifesciences</Link>
                </li>
                <li>
                  <Link to="/solutions/banking-finance-insurance">Banking Finance & Insurance</Link>
                </li>
                {/* <li>
                  <Link to="/solutions/e-commerce-solutions"> E-Commerce Solutions </Link>
                </li> */}
                <li>
                  <Link to="/solutions/on-demand-delivery">On Demand Delivery</Link>
                </li>
                <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li>
                <li>
                  <Link to="/solutions/transport-logistics">Transport Logistics</Link>
                </li>
                <li>
                  <Link to="/solutions/education-eLearning">Education & eLearning</Link>
                </li>
                  <li>
                    <Link to="/solutions/enterprise-retail-manufacturing">Enterprise Retail & Manufacturing</Link>
                  </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How an eCommerce Website Can Improve My Business? 
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Utilizing an eCommerce website and app can significantly expand your customer base, leveraging robust analytics functionalities within the portal to provide insights essential for analyzing critical factors such as customer preferences, habits, lifestyles, spending patterns, and more. These insights prove invaluable in crafting effective sales strategies, optimizing product placements, and developing features aimed at enhancing overall customer experience.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the different types of eCommerce App Platforms?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Some of the leading eCommerce app platforms include Shopify, Magento, Drupal, WooCommerce, BigCommerce, 3dcart, Volusion, Wix, Big Cartel, EKM, Core Commerce, X-Cart, Pinnacle-chart, and others, offering a diverse range of features and functionalities to cater to various business needs and preferences.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How to choose the right eCommerce Platform for my website/app?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Selecting the ideal eCommerce platform hinges on the size and requirements of your business. For startups seeking rapid entry into the market, Shopify emerges as the premier choice. Small to medium enterprises (SMEs) often find WooCommerce to be the most suitable platform, while brands operating medium to large online stores typically opt for Magento. For larger websites with intricate features, Drupal eCommerce stands out as the preferred solution. Nonetheless, the selection of a platform is contingent upon the unique needs of your business. For personalized recommendations tailored to your specific requirements, feel free to reach out to us. We're only a call away, ready to assist you.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech{" "}
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
