// PHP Core, MySQL, Laravel. Salary Range - 50000 +
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function App_development() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                  A dependable and trustworthy source
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                  Mobile App Development
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Are you looking for a customized mobile app development service? You're at the right place! 
                  <br />
                  Link Tech Builds Mobile App for Every User, platform & Industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="container rSecStyle" data-aos="fade-up">
          <div className="row gy-4 wrapper">
            {/* <!-- End post list item --> */}
            <div className="col-lg-9">
              <h1>
                We are building Innovative & Unique Mobile Apps With latest
                Technologies
              </h1>
              <article className="mt-4">
                <div className="servSecImg">
                <img
                  className="img-fluid"
                  src="/assets/images/services/mobile-app-development-image.jpg"
                  alt=""
                />
                </div>
                <img
                  src="/assets/images/services/app-development.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">iOS App Development Services</a>
                </h2>
                <h5>
                  Embedding intelligence to construct robust, inventive, and
                  scalable iOS app solutions that influence strategic business
                  choices.
                </h5>
                <p>
                  Our iOS App Development Services encapsulate a comprehensive
                  range of offerings tailored to meet the unique needs and
                  aspirations of businesses seeking to establish a robust
                  presence on Apple's ecosystem. From ideation to deployment and
                  ongoing support, our team of seasoned developers brings
                  proficiency and innovation to every stage of the development
                  journey. At the heart of our services lies a commitment to
                  delivering bespoke solutions that align perfectly with your
                  vision and objectives. We leverage the latest tools,
                  frameworks, and best practices to craft high-performance,
                  feature-rich iOS applications that resonate with users and
                  drive engagement. Whether you require a sleek consumer-facing
                  app or a powerful enterprise solution, we possess the
                  expertise to bring your ideas to life. Our approach to iOS app
                  development emphasizes seamless user experiences, elegant
                  design, and optimal performance across all Apple devices. We
                  prioritize user interface (UI) and user experience (UX) design
                  to ensure intuitive navigation and meaningful interactions,
                  enhancing user satisfaction and retention. Furthermore, our
                  services extend beyond mere development, encompassing thorough
                  testing, rigorous quality assurance, and ongoing maintenance
                  and support. We are committed to delivering solutions that not
                  only meet but exceed expectations, ensuring scalability,
                  security, and reliability in every aspect of our offerings.
                  Partner with us to unlock the full potential of iOS app
                  development and embark on a journey towards innovation,
                  growth, and success in the dynamic world of mobile technology.
                </p>
                <img
                  src="/assets/images/services/android-development.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Android App Development Services</a>
                </h2>
                <h5>
                  Link Tech modernized Android app development services deliver
                  unmatched business value that drives growth at every stage.
                </h5>
                <p>
                  Our Android App Development Services are meticulously crafted
                  to empower businesses with cutting-edge solutions tailored to
                  the dynamic Android ecosystem. We offer end-to-end development
                  services, from conceptualization to deployment and ongoing
                  support, ensuring that our clients' visions are transformed
                  into impactful digital experiences. With a team of skilled
                  developers proficient in the latest technologies and
                  frameworks, we specialize in creating robust, scalable, and
                  feature-rich Android applications that resonate with users and
                  drive business growth. Whether you need a sleek consumer app,
                  a powerful enterprise solution, or a groundbreaking
                  innovation, we have the expertise to bring your ideas to life.
                </p>
                <p>
                  Our approach to Android app development prioritizes
                  user-centric design, seamless functionality, and optimal
                  performance across a diverse range of devices and screen
                  sizes. We employ industry best practices and adhere to
                  stringent quality standards to deliver solutions that not only
                  meet but exceed expectations. From intuitive user interfaces
                  to seamless backend integrations and advanced functionalities,
                  our Android apps are engineered to deliver unparalleled user
                  experiences and drive engagement. We understand the importance
                  of staying ahead of the curve in a rapidly evolving digital
                  landscape, which is why we continuously innovate and adapt to
                  emerging trends and technologies. Moreover, our commitment to
                  excellence extends beyond development, encompassing
                  comprehensive testing, rigorous quality assurance, and ongoing
                  maintenance and support to ensure that your app remains
                  secure, reliable, and future-proof. Partner with us to unlock
                  the full potential of Android app development and embark on a
                  journey towards innovation, differentiation, and success in
                  the competitive app market.
                </p>
                <img
                  src="/assets/images/services/flutter.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Our Flutter App Development Services</a>
                </h2>
                <h5>
                  Engage users with minimal designs and fluid performance! Hire
                  expert Flutter app developers for cross platform app
                  development services.
                </h5>
                <p>
                  With our designers' proficiency in crafting sleek, minimalist,
                  and user-friendly designs, we elevate your product to new
                  heights. Our focus on aligning design with key business
                  objectives ensures that every aspect of your product serves a
                  purpose. Utilizing industry-leading design tools such as CSS3,
                  Photoshop, and others, we bring your vision to life with
                  precision and finesse. Whether it's creating captivating user
                  interfaces or enhancing overall user experience, our team is
                  dedicated to delivering designs that not only look stunning
                  but also drive meaningful results for your business.
                </p>
                <img
                  src="/assets/images/services/react-nativ.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">React Native App Development Services</a>
                </h2>
                <h5>
                  Develop a cross-platform mobile application that seamlessly
                  operates on both iOS and Android devices, ensuring a fluid and
                  intuitive user experience throughout.{" "}
                </h5>
                <p>
                  {" "}
                  Our React Native App Development Services offer efficient
                  solutions for building cross-platform mobile applications.
                  With React Native's flexibility, we create high-performance
                  apps that deliver native-like experiences on both iOS and
                  Android platforms. Our experienced team utilizes React
                  Native's reusable components and streamlined development
                  processes to accelerate time-to-market while maintaining
                  exceptional quality. From concept to deployment, we prioritize
                  user experience, functionality, and performance to ensure that
                  your app stands out in the competitive market. Partner with us
                  to leverage the power of React Native and unlock the potential
                  of your mobile app initiatives.
                </p>
                <img
                  src="/assets/images/services/pwa.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Our PWA App Development Services</a>
                </h2>
                <h5>
                  Boost your revenues and double your conversion rates faster
                  than ever with this modern technology
                </h5>
                <p>
                  Our PWA (Progressive Web App) Development Services empower
                  businesses with fast, reliable, and engaging web applications.
                  Leveraging the latest web technologies, we create PWAs that
                  offer seamless experiences across devices and platforms,
                  including desktops, smartphones, and tablets. With features
                  like offline capabilities, push notifications, and responsive
                  design, our PWAs provide users with a native app-like
                  experience directly through the web browser. Partner with us
                  to harness the benefits of PWAs, including increased user
                  engagement, broader reach, and improved performance, driving
                  growth and success for your business in the digital landscape.
                </p>
              </article>
            </div>
            <aside className="col-lg-3"
            >
              <div className="servSecImg">
              <img
                src="/assets/images/services/concept-development-device-flat.jpg"
                alt=""
              />
              </div>
              <div
                className="footer-widget lineStyle mt-5"
              >
                <div className="ltpColor mx-4">
                  <h3>Services</h3>
                </div>
                <ul>
                  {/* <li><Link to="/services/app-development">Mobile App Developemnt</Link></li> */}
                  <li>
                    <Link to="/services/web-development">Web Developemnt</Link>
                  </li>
                  <li>
                    <Link to="/services/software-development">
                      Software Developemnt
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/it-consulting">IT Consulting</Link>
                  </li>
                  <li>
                    <Link to="/services/digital-marketing">
                      Digital Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">Cloud Services</Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                </ul>
              </div>
            </aside>
            {/* <!-- End post list item --> */}
          </div>
          {/* <!-- End recent posts list --> */}
        </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the stages of the mobile app development process?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          Conducting thorough project requirement analysis and
                          defining clear goals are pivotal steps in our
                          approach.
                        </li>
                        <li>
                          App development: Planning, coding, and building.
                        </li>
                        <li>App testing: Making the app 100% bug-free.</li>
                        <li>
                          Deployment: Launching the app and deploying it to the
                          App Store & Play Store.
                        </li>
                        <li>
                          Monitoring & complete support: Updating your app with
                          new features and functionalities.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the steps involved in the mobile app design
                      process?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      The most important steps involved in our journey to the
                      mobile app design process are:
                      <ul>
                        <li>UX Analysis</li>
                        <li>Competitive Research</li>
                        <li>UX Wireframing</li>
                        <li>Usability Testing</li>
                        <li>Designing Style Guide</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Will you help us in publishing the app on App Store/Play
                      Store?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Indeed, we serve as your comprehensive app development
                      partner, overseeing every aspect of the process from
                      inception to deployment. From meticulous app planning and
                      analysis to seamless design, development, and deployment,
                      we handle each stage with precision and expertise.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-4"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How much does Mobile App Development Cost?
                    </button>
                  </h3>
                  <div
                    id="faq-content-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      The critical determinants influencing app development
                      costs encompass your preferred platform, UI/UX design
                      intricacy, feature set, complexities, potential
                      third-party integrations, and ongoing support expenses.
                      For an estimation tailored to your project, please share
                      your requirements with us at support@linktech.com.sa, and
                      we'll promptly provide you with a personalized quote.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}                
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>                        
                        <div className="col-md-6">
                          <h5>By sending this form I agree to the Link Tech <Link to="/privacy-policy"> Privacy Policy</Link></h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
