import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Blogs() {
  const particle = React.useRef(null);

  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2 data-aos="fade-up">Blogs</h2>
                <p data-aos="fade-up" data-aos-delay="400">
                  Discover the freshest narratives, expert insights, and our
                  journey in Digital Technologies, Enterprise Mobility, and Web
                  Development realms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="recent-posts" className="recent-posts sections-bg">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-4">
              <article>
                <Link to="/blogs/crafting-a-car-service-center-application-guide">
                  <div className="post-img">
                    <img
                      src="/assets/images/blog/Crafting-Car-Service.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="post-category mt-3">MOBILE APP DEVELOPMENT</p>
                  <h2 className="title">
                    Crafting a Car Service Center Application Guide.
                  </h2>
                </Link>
              </article>
            </div>
            {/* <!-- End post list item --> */}

            <div className="col-lg-4">
              <article>
                <Link to="/blogs/decide:-no-code-vs-custom-websites-development">
                  <div className="post-img">
                    <img
                      src="/assets/images/blog/Custom-Websites-Development.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="post-category mt-3">WEB DEVELOPMENT</p>
                  <h2 className="title">
                    Decide: No-code vs Custom Websites Development.
                  </h2>
                </Link>
              </article>
            </div>
            {/* <!-- End post list item --> */}

            <div className="col-lg-4">
              <article>
                <Link to="/blogs/ai-revolutionizing-agriculture:-farming's-future-unveiled">
                  <div className="post-img">
                    <img
                      src="/assets/images/blog/AI-Revolutionizing-Agriculture.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <p className="post-category mt-3">ARTIFICIAL INTELLIGENCE</p>
                  <h2 className="title">
                    AI Revolutionizing Agriculture: Farming's Future Unveiled.
                  </h2>
                </Link>
              </article>
            </div>
            {/* <!-- End post list item --> */}
          </div>
          {/* <!-- End recent posts list --> */}
        </div>
      </section>

      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Link Tech FAQ's</h2>
            <p>
              Below are some of the questions that may arise in your mind and
              their solutions
            </p>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What Makes Link Tech Unique in Enterprise Software
                      Development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      At Link Tech, we stand out by combining business
                      consulting, workflow automation, and data analytics to
                      create an integrated dashboard, simplifying and enhancing
                      your enterprise operations.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How Does Link Tech Handle Project Deadlines?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      The efficient project deadline management at Link Tech
                      comprises the following practices:
                      <ul>
                        <li>
                          <strong>
                            Agile Practices for On-Time Delivery:{" "}
                          </strong>
                          We adopt agile practices, ensuring increased
                          visibility, flexibility and efficiency in meeting your
                          deadlines.
                        </li>
                        <li>
                          <strong>Your Project, Your Ownership: </strong>
                          Experience complete control over your project's
                          progress. We believe in empowering you with decision
                          making, goal setting and full ownership.
                        </li>
                        <li>
                          <strong>Advanced Collaboration Tools: </strong>Utilize
                          cutting-edge collaboration tools that streamline
                          communication and coordination, keeping the project on
                          track.
                        </li>
                        <li>
                          <strong>Scrum-Based Approach for Efficiency: </strong>
                          Our scrum-based methodology fosters quick adaptations
                          and responsive changes, ensuring timely project
                          completion.
                          <br />
                          By integrating these practices, we guarantee not just
                          adherence to deadlines but also a seamless,
                          client-driven project development experience, tailored
                          to the dynamic needs of enterprise-level operations.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How Does Link Tech Stay Ahead with the Latest Technology
                      Trends?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Link Tech not only stays abreast of the latest technology
                      trends but also ensures that these advancements are
                      effectively integrated into solutions that drive value for
                      our enterprise clients. We implement following strategies:
                      <ul>
                        <li>
                          <strong>Center of Excellence (CoE): </strong>We have
                          established a dedicated Center of Excellence focused
                          on emerging technology trends & new frameworks. This
                          hub is our innovation powerhouse, continuously
                          exploring and integrating new tech advancements.
                        </li>
                        <li>
                          <strong>Strategic Industry Partnerships: </strong>We
                          collaborate with leading technology, solutions firms
                          and academic institutions, gaining early access to
                          cutting-edge tools and methodologies.
                        </li>
                        <li>
                          <strong>Active R&D Investments: </strong>A significant
                          portion of our resources is allocated to research and
                          development, fostering innovation in our products and
                          services.
                        </li>
                        <li>
                          <strong>Regular Technology Audits: </strong>Our
                          internal processes include frequent technology audits
                          to assess and upgrade our tools and systems in line
                          with the latest industry standards.
                        </li>
                        <li>
                          <strong>
                            Participation in Tech Conferences and Forums:{" "}
                          </strong>
                          We actively participate in global tech conferences and
                          forums, keeping our finger on the pulse of emerging
                          trends and insights.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-4"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What Is Your Process for Quality Assurance and Testing?
                    </button>
                  </h3>
                  <div
                    id="faq-content-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Here's how Link Tech ensures quality assurance and testing
                      for delivering reliable and bug-free products:
                      <ul>
                        <li>
                          <strong>Quality Improvement Plans: </strong>Regularly
                          revisiting and updating our QA strategies ensures
                          continuous improvement in our processes and product
                          quality.
                        </li>
                        <li>
                          <strong>Test-Driven Development (TDD): </strong>
                          Adopting iterative methodology when Quality is of
                          utmost priority, employing TDD where tests are written
                          before the code, ensuring that software development is
                          guided by specific quality requirements.
                        </li>
                        <li>
                          <strong>Dedicated QA Team Structure: </strong>Our
                          well-defined QA team structure includes clear roles,
                          responsibilities, and success measures, ensuring
                          consistent quality across projects.
                        </li>
                        <li>
                          <strong>
                            Continuous Quality Control and Assurance:{" "}
                          </strong>
                          We distinguish between quality control (QC), which
                          examines the product or service for results, and
                          quality assurance (QA), which examines and improves
                          the processes leading to the end-product. This
                          holistic approach covers all aspects of quality, from
                          planning to delivery.
                        </li>
                        <li>
                          <strong>Comprehensive Documentation Control: </strong>
                          We manage the distribution and archiving of documents,
                          ensuring any changes are made through proper quality
                          control procedures.
                        </li>
                        <li>
                          <strong>
                            Automated Testing for critical Areas:{" "}
                          </strong>
                          To enhance performance, security, third party
                          integration's efficiency and accuracy, we apply
                          automated testing to critical areas of the software.
                        </li>
                        <li>
                          <strong>Risk-Based Testing Framework: </strong>We use
                          a risk-based approach to identify potential issues
                          early in the development cycle and address them
                          proactively.
                        </li>
                        <li>
                          <strong>
                            Adherence to Quality Assurance Certifications:{" "}
                          </strong>
                          We follow standards such as ISO 9000 and CMMI levels
                          to ensure our QA mechanisms are mature and reliable.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="call-to-action" className="call-to-action">
        <div
          className="container text-center aos-init aos-animate"
          data-aos="zoom-out"
        >
          <div className="row gy-4">
            <div className="col-lg-12">
              <h3>Let's Discuss your Projects</h3>
              <p>
                We pride ourselves with our ability to perform and deliver
                results. Use the form below to discuss your project needs with
                our team, we will get back asap
              </p>
              <a className="cta-btn" href="mailto:info@example.com">
                Conatct Us
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
