// PHP Core, MySQL, Laravel. Salary Range - 50000 +
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function It_consulting() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                  Your Indispensable Trusted Partner
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                  IT Consulting Services
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Craft innovative, future-proof, and scalable digital solutions
                  in partnership with a premier custom software development
                  company
                  <br />
                  renowned for its expertise and commitment to excellence in
                  technology advancement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="container rSecStyle" data-aos="fade-up">
          <div className="row gy-4 wrapper">
            {/* <!-- End post list item --> */}
            <div className="col-lg-9">
              <h1>Our IT Consulting Services</h1>
              <article className="mt-4">
              <div className="servSecImg">
                <img
                  className="img-fluid"
                  src="/assets/images/services/it-consulting.jpg"
                  alt=""
                />
              </div>
                <img
                  src="/assets/images/services/it-assessments.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">IT Assessments</a>
                </h2>
                <p>
                  Our team conducts thorough assessments of your business
                  processes to gain a comprehensive understanding of your IT
                  landscape, enabling us to enhance security measures and
                  optimize efficiency seamlessly.
                </p>
                <img
                  src="/assets/images/services/it-strategy-planning.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">IT Strategy & Planning</a>
                </h2>
                <p>
                  We offer our unwavering support by comprehensively grasping
                  your IT strategies and planning, guiding you towards strategic
                  technology investments that align perfectly with your business
                  objectives.
                </p>
                <img
                  src="/assets/images/services/digital-transformation.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Digital Transformation Consulting</a>
                </h2>
                <p>
                  Our team is dedicated to analyzing your business processes to
                  enhance operational efficiency, guiding you through the
                  digital transformation journey with strategic insights and
                  leadership.
                </p>
                <img
                  src="/assets/images/services/security-risk.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Security Risk Assessments</a>
                </h2>
                <p>
                  Given the heavy reliance on technology, businesses face
                  inherent security risks. We assess the risk level and
                  recommend proactive measures to safeguard your assets and
                  operations.
                </p>
              </article>
            </div>
            <aside
              className="col-lg-3"
            >
              <div className="servSecImg">
              <img
                src="/assets/images/services/it-consulting-small.jpg"
                alt=""
              />
              </div>
              <div className="footer-widget lineStyle mt-5"
              >
                <div className="ltpColor mx-4">
                  <h3>Services</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/services/app-development">
                      Mobile App Developemnt
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/web-development">Web Developemnt</Link>
                  </li>
                  <li>
                    <Link to="/services/software-development">
                      Software Developemnt
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/services/it-consulting">IT Consulting</Link>
                  </li> */}
                  <li>
                    <Link to="/services/digital-marketing">
                      Digital Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">Cloud Services</Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                </ul>
              </div>
            </aside>
            {/* <!-- End post list item --> */}
          </div>
          {/* <!-- End recent posts list --> */}
        </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Why IT consulting service are importance for business?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      In today's fiercely competitive landscape, a meticulous
                      approach to market entry is paramount. Entrusting expert
                      hands to navigate this terrain and gain invaluable
                      expertise is essential. Opting for IT Consulting Services
                      for businesses can streamline these processes seamlessly.
                      At Link Tech,we specialize in strategic planning and
                      precise execution of marketing and development strategies,
                      tailored to your unique business needs. Our support
                      extends beyond mere launch, ensuring the delivery of a
                      competitive, top-quality product.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What IT consulting service do we offer?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      We provide a diverse range of services aimed at catering
                      to a broad spectrum of clients in the competitive market,
                      offering tailored solutions to facilitate their entry and
                      success. Our services encompass Custom Web & Mobile
                      Application Development, Cloud and Data Migration, Digital
                      Transformation Consulting, Security Risk Assessment, IT
                      Strategy & Planning, and IT Assessment. These offerings
                      are meticulously designed to address the unique needs of
                      our clients and empower them to thrive in today's dynamic
                      business landscape.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What can I expect from Link Tech Consulting Service?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      At Link Tech, we foster close collaboration with our
                      clients to pinpoint challenges and future objectives,
                      enabling us to effectively define and achieve their
                      business goals. Our approach involves rigorous,
                      data-driven strategic planning to evaluate technologies
                      and recommend optimal solutions. We craft a guided roadmap
                      for business success, outlining investments and
                      meticulously implementing steps to deliver tangible
                      results. This methodical approach ensures that our clients
                      receive tailored strategies and solutions aligned with
                      their unique needs and objectives.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-4"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What approach does Link Tech follow to deliver IT
                      Consulting Services?
                    </button>
                  </h3>
                  <div
                    id="faq-content-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      At Link Tech, we follow industry-specific norms and
                      standard frameworks and we adhere best practices to
                      deliver required services that deal with our client's
                      demands.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>                        
                        <div className="col-md-6">
                          <h5>By sending this form I agree to the Link Tech <Link to="/privacy-policy"> Privacy Policy</Link></h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
