import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function Media_Entertainment() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                TRANSFORMING EXPERIENCES
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                Media & Entertainment Software Development
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Crafting visually captivating and immersive experiences through our innovative media
                  <br />
                  and entertainment software solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container rSecStyle"
        data-aos="fade-up"
      >
        <div className="row gy-4 wrapper">
          <div className="col-lg-9">
            <h1>
            Our Media & Entertainment Service Offerings 
            </h1>
            <article className="mt-4">
              <img
                src="/assets/images/solutions/music-streaming-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Music Streaming App: </a>
              </h2>
              <p>
              We engineer feature-rich and technologically advanced music streaming applications with a robust backend infrastructure, ensuring seamless management of large numbers of active listeners without any disruptions.
              </p>
              <img
                src="/assets/images/solutions/ott-streaming-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">OTT Streaming App: </a>
              </h2>
              <p>
              Engage our industry-leading experts to harness state-of-the-art technologies in crafting user-friendly, high-performance OTT platforms capable of accommodating extensive user bases and more.
              </p>
              <img
                src="/assets/images/solutions/gaming-application.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Gaming Application: </a>
              </h2>
              <p>
              We boast a team of gaming application specialists proficient across diverse platforms and genres. Leveraging cutting-edge technologies, we craft efficient and high-performing apps with stable infrastructure.
              </p>
              <img
                src="/assets/images/solutions/content-aggregation-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Content Aggregation App: </a>
              </h2>
              <p>
              Link Tech developers construct a meticulously structured application serving as a centralized hub for customers and businesses to swiftly navigate databases spanning various domains or topics.
              </p>
              <img
                src="/assets/images/solutions/image-editing-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Image Editing App: </a>
              </h2>
              <p>
              We build highly responsive image editing & sharing app to offer a next-level experience by integrating advanced technologies like AI based on geo-tagging functionality.
              </p>
              <img
                src="/assets/images/solutions/custom-erp-solution.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Custom ERP Solution: </a>
              </h2>
              <p>
              Our proficient media and entertainment team collaborates with organizations to develop customized solutions that precisely align with their unique business requirements, tailored to accommodate specific industry nuances and workflow intricacies.
              </p>
            </article>
          </div>
          <aside
            className="col-lg-3"
          >
            <div className="servSecImg">
            <img
              src="/assets/images/solutions/media-&-entertainment-software-development.jpg"
              alt=""
            />
            </div>
            <div
              className="footer-widget lineStyle mt-5"
            >
              <div className="ltpColor mx-4">
                <h3>Solutions</h3>
              </div>
              <ul>
                <li>
                  <Link to="/solutions/on-demand-delivery">On Demand Delivery</Link>
                </li>
                <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li>
                <li>
                  <Link to="/solutions/transport-logistics">Transport Logistics</Link>
                </li>
                <li>
                <Link to="/solutions/education-eLearning">Education & eLearning</Link>
                </li>
                <li>
                  <Link to="/solutions/enterprise-retail-manufacturing">Enterprise Retail & Manufacturing</Link>
                </li>
                <li>
                  <Link to="/solutions/travel-hospitality">Travel & Hospitality</Link>
                </li>
                {/* <li>
                  <Link to="/solutions/media-entertainment">Media & Entertainment</Link>
                </li> */}
                <li> <Link to="/solutions/social-networking">Social Networking</Link>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Will the app be customized as per my requirements?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Absolutely! At Link Tech, we prioritize close collaboration with our clients to ensure we fully grasp their business objectives and requirements. As a trusted leader in mobile app development, we keep you involved every step of the way, providing regular updates and seeking your approval before deploying the solution. Your satisfaction is our ultimate goal, and we spare no effort in delivering a tailored, top-notch app solution for your business.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Why should I choose Link Tech for media and entertainment app development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Link Tech is a leading web and app development company that specializes in media and entertainment app development that ensures to facilitate its clients from multiple aspects.
                      <p> <strong>Flexible engagement models: </strong>Adaptable collaboration structures</p>
                      <p><strong>A responsible and skilled development team: </strong>Competent and accountable development professionals</p>
                      <p><strong>Affordable solution with smooth deployment: </strong>Cost-effective implementation with seamless rollout</p>
                      <p><strong>Low latency and high-performing app solution: </strong>High responsiveness and optimal app performance</p>
                      <p><strong>An enhanced and agile development approach: </strong>Improved and nimble development methodology</p>
                      <p><strong>100% conversions and experimental solutions: </strong>Guaranteed conversions and innovative approaches</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How much does it cost to develop a media and entertainment application?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    The estimated development cost of a media and entertainment application can be variable and relies on various parameters i.e. design, project complexity, product scope, platforms, technologies, development team and location, and many more.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech{" "}
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
