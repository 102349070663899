import React, { useEffect } from "react";
import { useCountUp } from "react-countup";

export default function Teams() {
  const particle = React.useRef(null);

  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });
  
    //number counting
    useCountUp({
      ref: 'counter',
      end: 7,
      enableScrollSpy: true,
      scrollSpyDelay: 100,
    });
    useCountUp({
      ref: 'counter1',
      end: 5,
      enableScrollSpy: true,
      scrollSpyDelay: 100,
    });
    useCountUp({
      ref: 'counter2',
      end: 5,
      enableScrollSpy: true,
      scrollSpyDelay: 100,
    });
 
    // When click url then show page top section
    useEffect(() => {
      window.scrollTo(0,0)
    },[])

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2 data-aos="fade-up">Teams</h2>
                <p data-aos="fade-up" data-aos-delay="400">
                Meet our expert IT team, dedicated to delivering innovative solutions and exceptional service to drive your business forward.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="team" className="team sections-bg">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="row gy-4">
            <div
              className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="member">
                <img
                  src="/assets/images/team/team-1.jpg"
                  className="img-fluid"
                  alt=""
                />
                <h4>LinkTech Employee</h4>
                <span>Application Manager</span>
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- End Team Member --> */}

            <div
              className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="member">
                <img
                  src="/assets/images/team/team-1.jpg"
                  className="img-fluid"
                  alt=""
                />
                <h4>LinkTech Employee</h4>
                <span>Social Media</span>
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- End Team Member --> */}

            <div
              className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="member">
                <img
                  src="/assets/images/team/team-1.jpg"
                  className="img-fluid"
                  alt=""
                />
                <h4>LinkTech Employee</h4>
                <span>Content Writer</span>
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- End Team Member --> */}

            <div
              className="col-xl-3 col-md-6 d-flex aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="member">
                <img
                  src="/assets/images/team/team-1.jpg"
                  className="img-fluid"
                  alt=""
                />
                <h4>LinkTech Employee</h4>
                <span>Business Manager</span>
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- End Team Member --> */}
          </div>
        </div>
      </section>
      <div id="stats-counter" className="call-to-action stats-counter section">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4 align-items-center">
            <div className="col-lg-4">
              <div className="stats-item d-flex flex-column align-items-center">
                <div className="icon">
                  <img
                    src="/assets/images/icons/happy-clients.png"
                    alt="icon"
                  />
                </div>
                <span id='counter'></span>
                <p>
                  <span>Happy Clients</span> Clients satisfaction is our first priority
                </p>
              </div>
              {/* <!-- End Stats Item --> */}
            </div>
            <div className="col-lg-4">
              <div className="stats-item d-flex flex-column align-items-center">
                <div className="icon">
                  <img
                    src="/assets/images/icons/complete-projects.png"
                    alt="icon"
                  />
                </div>
                <span id='counter1'></span>
                <p>
                  <span>Completed Projects</span> Successfully delivered the projects on time
                </p>
              </div>
              {/* <!-- End Stats Item --> */}
            </div>
            <div className="col-lg-4">
              <div className="stats-item d-flex flex-column align-items-center">
                <div className="icon">
                  <img
                    src="/assets/images/icons/hours-support.png"
                    alt="icon"
                  />
                </div>
                <span id='counter2'></span>
                <p>
                  <span>Running Projects</span> Discover excellence in development
                </p>
              </div>
              {/* <!-- End Stats Item --> */}
            </div>
          </div>
          <div className="row pt-5 gy-4 text-center">
            <div className="col-lg-12 pt-5">
              <h3>Let's Discuss your Projects</h3>
              <p>
                We pride ourselves with our ability to perform and deliver
                results. Use the form below to discuss your project needs with
                our team, we will get back asap
              </p>
              <a className="cta-btn" href="mailto:info@example.com">
                Conatct Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
