// PHP Core, MySQL, Laravel. Salary Range - 50000 +
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function App_development() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">Place Your Brand's Trust</p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                  Digital Marketing Company
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Achieve remarkable outcomes with Link Tech's exceptional
                  digital strategy and top-tier digital marketing services,
                  propelling your business towards unparalleled success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container rSecStyle" data-aos="fade-up">
        <div className="row gy-4 wrapper">
          {/* <!-- End post list item --> */}
          <div className="col-lg-9">
            <h1>Our Best-in-class Digital Marketing Services</h1>
            <article className="mt-4">
            <div className="servSecImg">
              <img
                className="img-fluid"
                src="/assets/images/services/digital-marketing.jpg"
                alt=""
              />
            </div>
              <img
                src="/assets/images/services/social-media-marketing.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Social Media Marketing (SMM)</a>
              </h2>
              <p>
                Elevate your brand authority, enhance engagement, and amplify
                traffic through our comprehensive SMM services. Our adept team
                crafts compelling content, fosters meaningful audience
                connections, and meticulously tracks performance to ensure
                impactful results.
              </p>
              <img
                src="/assets/images/services/social-media-consulting.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Social media consulting</a>
              </h2>
              <p>
                Grow your social media presence and reach your target audience
                with our expert guidance. We'll help you create a social media
                strategy, develop engaging content, and track your results.
              </p>
              <img
                src="/assets/images/services/social-media-strategy.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Our Social Media Strategy Development</a>
              </h2>
              <p>
                This service involves creating a comprehensive social media
                strategy tailored to a client's specific goals and target
                audience. It includes defining objectives, identifying key
                platforms, and outlining content and engagement strategies.
              </p>
              <img
                src="/assets/images/services/content-creation.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Content Creation and Management</a>
              </h2>
              <p>
                This service concentrates on generating captivating and
                shareable content across various social media platforms. It
                encompasses crafting compelling text, images, videos, and
                multimedia elements, alongside scheduling and publishing content
                to guarantee regular and timely updates for your audience.
              </p>
              <img
                src="/assets/images/services/social-media-advertising.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Social Media Advertising</a>
              </h2>
              <p>
                This service entails executing paid advertising campaigns on
                social media platforms to enhance brand visibility, expand
                audience reach, and stimulate traffic or conversions. It
                encompasses ad creation, precise targeting, strategic bidding,
                and vigilant monitoring of campaign performance to optimize
                results effectively.
              </p>
              <img
                src="/assets/images/services/social-media-analytics.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Social Media Analytics and Reporting</a>
              </h2>
              <p>
                This service centers on monitoring and analyzing social media
                performance metrics to gauge effectiveness. It involves
                assessing metrics such as reach, engagement, conversions, and
                other key indicators to provide valuable insights into the
                impact of social media efforts.
              </p>
              <img
                src="/assets/images/services/keyword-research-and-analysis.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Keyword Research and Analysis</a>
              </h2>
              <p>
              Enhancing your website's visibility in search engine results relies on strategic keyword optimization. Through the judicious use of relevant keywords, we elevate your website's search engine ranking, ensuring increased visibility and exposure to your target audience.
              </p>
              <img
                src="/assets/images/services/on-page-optimization.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">On-Page Optimization</a>
              </h2>
              <p>
              To enhance your website's search engine rankings, it's crucial to focus on the website's structure, meta tags, and content. By optimizing these elements strategically, you can achieve significant improvements in search engine results.
              </p>
              <img
                src="/assets/images/services/off-page-optimization.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Off-Page Optimization</a>
              </h2>
              <p>
              An essential aspect of establishing your website's online presence is building high-quality backlinks. This demonstrates to search engines your credibility and reliability as a valuable source of information.
              </p>
              <img
                src="/assets/images/services/email-marketing-company.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Email Marketing</a>
              </h2>
              <p>
              We create email marketing campaigns that are tailored to your specific business goals.
              </p>
              <img
                src="/assets/images/services/email-marketing-strategy-development.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Email Marketing Strategy Development</a>
              </h2>
              <p>
              Our team will devise a comprehensive email marketing strategy customized to your unique objectives, encompassing goal definition, platform identification, and more, to ensure optimal results.
              </p>
              <img
                src="/assets/images/services/customized-and-engaging-email.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Customized and Engaging Email</a>
              </h2>
              <p>
              Our email marketing content strategy includes crafting engaging and shareable content for your email campaigns, ensuring regular and timely updates to captivate your audience.
              </p>
              <img
                src="/assets/images/services/email-marketing-automation.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Email Marketing Automation</a>
              </h2>
              <p>
              We streamline your email marketing campaigns by automating processes such as sending welcome emails, abandoned cart reminders, and other triggered campaigns, ensuring timely and personalized communication with your audience.
              </p>              
            </article>
          </div>
          <aside className="col-lg-3">
          <div className="servSecImg">
            <img
              src="/assets/images/services/digital-marketing-small.jpg"
              alt=""
            />
          </div>
            <div className="footer-widget lineStyle mt-5">
              <div className="ltpColor mx-4">
                <h3>Services</h3>
              </div>
              <ul>
                <li>
                  <Link to="/services/app-development">
                    Mobile App Developemnt
                  </Link>
                </li>
                <li>
                  <Link to="/services/web-development">Web Developemnt</Link>
                </li>
                <li>
                  <Link to="/services/software-development">
                    Software Developemnt
                  </Link>
                </li>
                <li>
                  <Link to="/services/it-consulting">IT Consulting</Link>
                </li>
                {/* <li>
                    <Link to="/services/digital-marketing">
                      Digital Marketing
                    </Link>
                  </li> */}
                <li>
                  <Link to="/services/cloud-services">Cloud Services</Link>
                </li>
                <li>
                  <Link to="/services/cloud-services">Up Comming Services</Link>
                </li>
                <li>
                  <Link to="/services/cloud-services">Up Comming Services</Link>
                </li>
                <li>
                  <Link to="/services/cloud-services">Up Comming Services</Link>
                </li>
              </ul>
            </div>
          </aside>
          {/* <!-- End post list item --> */}
        </div>
        {/* <!-- End recent posts list --> */}
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How would my business benefit from digital marketing
                      services?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Digital marketing services can help your business in a
                      number of ways, including:
                      <ul>
                        <li>
                          Increased brand awareness: Digital marketing can help
                          you to increase brand awareness by reaching a wider
                          audience with your message.
                        </li>
                        <li>
                          Lead generation: Digital marketing can help you to
                          generate leads by providing potential customers with
                          information about your products or services.
                        </li>
                        <li>
                          Sales: Digital marketing can help you to increase
                          sales by driving traffic to your website or landing
                          pages.
                        </li>
                        <li>
                          Customer retention: Digital marketing can help you to
                          retain customers by providing them with valuable
                          content and offers.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Why do businesses need digital marketing consultancy or
                      digital marketing consulting services?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Businesses need digital marketing consultancy or digital
                      marketing consulting services because the digital
                      landscape is constantly changing. To keep ahead of the
                      competition, businesses must have a strong digital
                      presence and be able to reach their target audience
                      through digital platforms. They can give firms the
                      experience and direction they require to build and
                      implement effective digital marketing initiatives.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How Link Tech Digital Marketing Expert Helps to grow the
                      Brand?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Link Tech's digital marketing experts can help businesses
                      to grow their brands in several ways, including:
                      <ul>
                        <li>Developing a digital marketing strategy</li>
                        <li>Creating and managing content</li>
                        <li>Running paid advertising campaigns</li>
                        <li>Analyzing results</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-4"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Can social media really boost my business, or is it just
                      for posting videos and memes?
                    </button>
                  </h3>
                  <div
                    id="faq-content-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Social media is not just for viral content (although that
                      helps too). When you do right social media marketing for
                      your business, it establishes a connection with your
                      target audience, exhibit your products, and create a
                      unique brand identity. Consider it as a digital shop-front
                      where your clients can interact and express their
                      opinions.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-5"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Why should I pay for clicks when I can get them for free
                      through SEO?
                    </button>
                  </h3>
                  <div
                    id="faq-content-5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Think of PPC as the express lane for your website. SEO is
                      fantastic for the long haul, while PPC serves as a quick
                      route to bid on your keyword and feature it prominently at
                      the top position of the SERP page. It's like paying for a
                      VIP pass to the online party - you skip the line and get
                      noticed faster.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech{" "}
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
