// PHP Core, MySQL, Laravel. Salary Range - 50000 +
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Web_development() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });
 
  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                  ASTONISHINGLY INTERACTIVE
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                Web Development Services
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                > Implementing customized website solutions centered around the customer's needs and focused on client satisfaction,
                  <br />
                  we empower businesses to establish themselves as prominent brands in the continually evolving digital realm.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="container rSecStyle" data-aos="fade-up">
          <div className="row gy-4 wrapper">
            {/* <!-- End post list item --> */}
            <div className="col-lg-9">
              <h1>
              Our Custom Web Development Services
              </h1>
                <p>Enhance the visual appeal and functionality of your website with our intuitive, personalized, and responsive web development solutions. We specialize in integrating third-party services, developing custom plugins, and implementing specific features to elevate your online presence and meet your unique business needs.</p>
              <article className="mt-4">
              <div className="servSecImg">
                <img
                  className="img-fluid"
                  src="/assets/images/services/web-development-image.jpg"
                  alt=""
                />
              </div>
                <img
                  src="/assets/images/services/cms.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">CMS Website Development</a>
                </h2>
                <p>
                Delivering unmatched CMS Website Development Services, we excel in crafting captivating and top-tier solutions tailored precisely to your business requirements. Additionally, our expertise extends to seamless platform migrations, ensuring a smooth transition to any CMS platform while maintaining quality and engagement throughout.
                </p>
                <img
                  src="/assets/images/services/responsive.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Responsive Website Design</a>
                </h2>
                <p>
                Our expertise lies in crafting responsive websites and landing pages that dynamically adapt to users' devices and screen sizes, optimizing user experience across all platforms. These tailored solutions empower businesses to drive traffic, generate leads, and boost sales effectively.
                </p>
                <img
                  src="/assets/images/services/e-commerce.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">E-Commerce Website Development</a>
                </h2>
                <p>
                At Link Tech we prioritize staying abreast of the latest market trends and customer preferences to develop tailored e-commerce websites, whether for B2B or B2C purposes. Our commitment to leveraging cutting-edge technology ensures that your online platform aligns seamlessly with your business objectives and meets the evolving needs of your target audience.
                </p>
                <img
                  src="/assets/images/services/single-page.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Single Page Website Development</a>
                </h2>
                <p>
                We specialize in assisting clients in creating image-centric and mobile-responsive single-page applications (SPAs) that prioritize simplicity, swift page loading, and clutter-free navigation. Our focus on user-centric design enhances lead generation and facilitates rapid conversions, fostering a seamless and engaging online experience.
                </p>
                <img
                  src="/assets/images/services/enterprise-web.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Enterprise Web App Development</a>
                </h2>
                <p>
                Is your enterprise hindered by legacy systems, impeding efficiency and growth in the digital marketplace? Link Tech offers comprehensive app modernization services to boost business agility, streamline automation, and foster innovation, empowering you to thrive in today's dynamic landscape.
                </p>
              </article>
            </div>
            <aside className="col-lg-3">
            <div className="servSecImg">
              <img src="/assets/images/services/web-development-image-small.jpg" alt=""/>
            </div>
              <div className="footer-widget lineStyle mt-5"
              >
                <div className="ltpColor mx-4">
                  <h3>Services</h3>
                </div>
                <ul>
                  <li><Link to="/services/app-development">Mobile App Developemnt</Link></li>
                  {/* <li>
                    <Link to="/services/web-development">Web Developemnt</Link>
                  </li> */}
                  <li>
                    <Link to="/services/software-development">
                      Software Developemnt
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/it-consulting">IT Consulting</Link>
                  </li>
                  <li>
                    <Link to="/services/digital-marketing">
                      Digital Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">Cloud Services</Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">Up Comming Services</Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">Up Comming Services</Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">Up Comming Services</Link>
                  </li>
                </ul>
              </div>
            </aside>
            {/* <!-- End post list item --> */}
          </div>
          {/* <!-- End recent posts list --> */}
        </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How can I optimize a web for SEO?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    For SEO the website content plays a vital role. Additionally, the technical aspects of SEO Service include are, optimized web page titles, alt tags for images, subpage names, domains, and others. 
                    <br/>
                    The web application development company helps in SEO with quick page loading, adding meta tag, and proper titles, and integrating an SSL certificate. All these aspects help to improve search ranking of the website or SPA on Google Search Console. 
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How much does it cost to build a website?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    The Front end and back-end Development cost of a website can vary on the various project scope. It includes project size, features and functionalities, advanced technology integration, development team expertise, size, and more. <br/>
                    Moreover, to define the web development cost, it is vital to define and convey your business objectives accurately to the web development company. The experts can only understand your business specific, objective and translate your requirements accurately into the real world.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Which programming languages does your development team use for custom web development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    At Link Tech, we understand each project's requirements are different and based on that we define the tech stack to deliver excellent results. However, some common and trendy programming languages that our efficient and highly proficient developers use is PHP, Python, Java, jQuery, JavaScript, and more.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-5"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the benefits of outsourcing web app Development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Some of the main benefits of outsourcing your web app development are:
                    <ul>
                      <li>Flexible working time-zone</li>
                      <li>Quick project development</li>
                      <li>Access to the experienced talent pool</li>
                      <li>Advanced technology assistance</li>
                      <li>Assured quality services to your unique business</li>
                      <li>A better understanding of various platforms</li>
                    </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>                        
                        <div className="col-md-6">
                          <h5>By sending this form I agree to the Link Tech <Link to="/privacy-policy"> Privacy Policy</Link></h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
