import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Why_Choose_Us() {
  const particle = React.useRef(null);

  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-10 text-center">
                <h2 data-aos="fade-up">Why Choose Us</h2>
                <p data-aos="fade-up" data-aos-delay="400">
                  Choose excellence choose turnkey IT services under one roof!
                  We are your tech partner to boost your worldwide business
                  operations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="recent-posts" className="sections-bg">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            {/* <!-- End post list item --> */}
            <div className="col-lg-6">
              <article>
                <img
                  src="/assets/images/why-us/expertise-and-experience.png"
                  alt=""
                  className="mb-2"
                ></img>
                <h1>Expertise and Experience</h1>
                <p>
                  In the realm of IT services, expertise and experience stand as
                  paramount pillars of distinction, and it is these qualities
                  that define our company as a top-tier choice. With a proven
                  track record of delivering innovative solutions across diverse
                  industries, our team boasts a wealth of expertise honed
                  through years of hands-on experience. <br/><br/>
                  
                  Our seasoned professionals possess deep-rooted knowledge in cutting-edge
                  technologies, allowing us to craft tailored solutions that
                  precisely meet the unique needs of our clients. Furthermore,
                  our extensive experience navigating complex IT landscapes
                  equips us with the insights and foresight necessary to
                  anticipate challenges and provide proactive solutions. <br/><br/>
                  
                  By choosing us as your IT partner, you're tapping into a
                  reservoir of proficiency and know-how, ensuring that your
                  projects are executed with precision and excellence. Let our
                  expertise and experience propel your business towards
                  unparalleled success in the digital realm.
                </p>
                <img
                  src="/assets/images/why-us/customer-centric-approach.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1>Customer-Centric Approach</h1>
                <p>
                  Choose us for our unwavering commitment to a customer-centric
                  approach. At our core, we prioritize your needs and
                  satisfaction above all else. Our tailored solutions are
                  designed with your success in mind, ensuring seamless
                  integration and maximum value.<br/><br/> With a focus on understanding
                  your unique challenges and goals, we go above and beyond to
                  deliver personalized support and guidance every step of the
                  way.<br/><br/> By choosing us, you're choosing a partner dedicated to
                  your success, ready to provide innovative solutions and
                  exceptional service to help your business thrive in the
                  digital age.
                </p>
                <img
                  src="/assets/images/why-us/timely-delivery.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1>Timely Delivery</h1>
                <p>
                  Choose us for our unwavering dedication to timely delivery.
                  With a finely tuned workflow and a team of skilled
                  professionals, we ensure that your projects are completed
                  promptly, without compromising on quality.<br/><br/> Our proactive
                  approach to project management allows us to anticipate and
                  address any potential delays, keeping your timelines on track.<br/><br/>
                  By partnering with us, you can trust that your deadlines will
                  be met with precision and reliability, giving you peace of
                  mind and allowing you to focus on your core business
                  objectives. Experience the confidence of working with a team
                  committed to delivering results on time, every time.
                </p>
                <img
                  src="/assets/images/why-us/integrity-gives-peace.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1>Our INTEGRITY Gives Peace of Mind</h1>
                <p>
                  It's in our name and why we were founded —Link Tech. We hold
                  ourselves to a high level of integrity in every aspect of our
                  business.<br/><br/> We believe that despite the services and solutions
                  we provide relationships, moral character, and trust are what
                  breed success in business and in our everyday lives.
                </p>
              </article>
            </div>
            <div className="col-lg-6">
            <article>
                <img
                  src="/assets/images/why-us/quality-product.png"
                  alt=""
                  className="mb-2"
                ></img>
                <h1>Quality Products</h1>
                <p>
                  When it comes to selecting an IT company, the quality of
                  products offered is non-negotiable, and it's precisely this
                  commitment to excellence that sets us apart. Our company
                  prides itself on delivering top-tier products meticulously
                  crafted to exceed industry standards and surpass client
                  expectations.<br/><br/> We leverage the latest technologies and best
                  practices to develop robust, reliable solutions that empower
                  your business to thrive in today's competitive landscape. Our
                  dedication to quality permeates every aspect of our product
                  development process, from initial concept to final delivery,
                  ensuring that each offering is finely tuned to address your
                  specific needs and challenges.<br/><br/> By choosing us as your IT
                  partner, you're not just investing in products; you're
                  investing in peace of mind, knowing that you're receiving the
                  highest caliber solutions designed to drive your success
                  forward. Experience the difference that quality makes and
                  elevate your business with our unparalleled products and
                  services.
                </p>
                <img
                  src="/assets/images/why-us/competitive-pricing.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1>Competitive Pricing</h1>
                <p>
                  Select us for our competitive pricing model that ensures
                  maximum value for your investment. We believe in offering
                  high-quality IT solutions at affordable rates, allowing you to
                  optimize your budget without compromising on excellence.<br/><br/> Our
                  transparent pricing structure eliminates hidden costs and
                  surprises, providing clarity and peace of mind throughout our
                  partnership. By choosing us, you're not only gaining access to
                  top-tier products and services but also maximizing your ROI.<br/><br/>
                  Trust us to deliver cost-effective solutions tailored to your
                  needs, empowering your business to succeed in today's
                  competitive market.
                </p>                
                <img
                  src="/assets/images/why-us/24-hours.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1>24/7 Support</h1>
                <p>
                  Select us for our round-the-clock support, ensuring that
                  assistance is always available whenever you need it. Our
                  dedicated team of experts is on standby 24/7 to address any
                  inquiries, concerns, or issues promptly and effectively.<br/><br/>
                  Whether it's day or night, weekday or weekend, you can rely on
                  us to provide timely assistance and guidance to keep your
                  systems running smoothly.<br/><br/> By choosing us, you're not just
                  getting cutting-edge IT solutions; you're gaining peace of
                  mind knowing that comprehensive support is just a phone call
                  or message away, empowering you to focus on your business with
                  confidence and assurance.
                </p>                
                <img
                  src="/assets/images/why-us/global-presence-approach.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1>Global presence approach</h1>
                <p>
                  At Link Tech, we bring the highest quality of service to your
                  doorstep through our hybrid global model. Our global presence
                  and highly skilled teams provide us with access to a wide
                  range of resources, guaranteeing that we have the right
                  personnel with the appropriate abilities for your project.<br/><br/>
                  Additionally, our local presence ensures that our services are
                  delivered without any disruption to your business operations.
                </p>
              </article>
            </div>
          </div>
          {/* <!-- End recent posts list --> */}
        </div>
      </section>
    </div>
  );
}
