import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function Real_Estate() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                  YOUR DIGITAL TRANSFORMATION PARTNER
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                  Real Estate Software Development
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  We are experts in building exceptional custom web and mobile
                  app solutions specifically designed for real estate
                  businesses,
                  <br />
                  fostering seamless connections between sellers, buyers,
                  brokers, and agents.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container rSecStyle" data-aos="fade-up">
        <div className="row gy-4 wrapper">
          <div className="col-lg-9">
            <h1>Our Real Estate App Service Offerings</h1>
            <article className="mt-4">
              <img
                src="/assets/images/solutions/real-estate-solution.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Custom Real-Estate Solution: </a>
              </h2>
              <p>
                We specialize in developing customized web and mobile
                applications seamlessly integrated with cutting-edge technology,
                aimed at elevating user experiences and extending the digital
                presence of businesses to new heights.
              </p>
              <img
                src="/assets/images/solutions/broker-management-solution.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Broker Management Solution: </a>
              </h2>
              <p>
                Our skilled developers engineer solutions dedicated to ensuring
                the secure management of broker details while facilitating
                effortless connections with all potential clients.
              </p>
              <img
                src="/assets/images/solutions/lead-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Lead Management: </a>
              </h2>
              <p>
                At Link Tech, we specialize in developing tailor-made CRM
                solutions for lead and sales management, empowering businesses
                to efficiently track leads and nurture strong client
                relationships.
              </p>
              <img
                src="/assets/images/solutions/module-development.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Module Development: </a>
              </h2>
              <p>
                To improve the quality of an existing solution, we integrate new
                features and functionalities, incorporating custom modules to
                meet the expectations of both clients and buyers.
              </p>
              <img
                src="/assets/images/solutions/property-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Property Management: </a>
              </h2>
              <p>
                We specialize in creating tailored solutions for efficient
                property management, effectively handling tasks such as
                bookings, reservations, cancellations, maintenance, and updates
                with ease and precision.
              </p>
              <img
                src="/assets/images/solutions/renting-properties.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Renting Properties: </a>
              </h2>
              <p>
                Our team of developers constructs comprehensive real estate
                rental solutions equipped with advanced features to facilitate
                virtual property inspections, rent management, and more,
                ultimately enhancing user engagement.
              </p>
            </article>
          </div>
          <aside className="col-lg-3">
          <div className="servSecImg">
            <img src="/assets/images/solutions/real-estate-software-development.jpg" alt="" />
          </div>
            <div className="footer-widget lineStyle mt-5">
              <div className="ltpColor mx-4">
                <h3>Solutions</h3>
              </div>
              <ul>
                <li>
                  <Link to="/solutions/healthcare-lifesciences">
                    Healthcare & Lifesciences
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/banking-finance-insurance">
                    Banking Finance & Insurance
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/e-commerce-solutions">
                    {" "}
                    E-Commerce Solutions{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/on-demand-delivery">
                    On Demand Delivery
                  </Link>
                </li>
                {/* <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li> */}
                <li>
                  <Link to="/solutions/transport-logistics">
                    Transport Logistics
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/education-eLearning">
                    Education & eLearning
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/enterprise-retail-manufacturing">
                    Enterprise Retail & Manufacturing
                  </Link>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How much does it cost to develop a real estate app?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    The pricing for our real estate app development services is tailored to your specific needs, including platform selection, feature requirements, third-party integrations, and project complexities. Get in touch with our business developer today to discuss your project requirements and receive a complimentary quote. Request yours now!
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Do you provide ongoing support and app maintenance post-development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    As a leading real estate app development firm, we excel in delivering comprehensive qualitative and performance analyses, coupled with round-the-clock support and maintenance services post-deployment, ensuring your app operates seamlessly and meets your evolving needs.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Do you provide SEO and Digital Marketing Services for Real Estate apps?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Accelerate your app's revenue and user base expansion through our array of digital marketing solutions, encompassing App Store Optimization (ASO), impactful Social Media Ads, and precision-driven Search Engine Optimization (SEO) strategies. Ready to propel your app to new heights? Connect with our seasoned digital marketing specialists today. Whether you prefer a quick phone call or an email, we guarantee a swift response within 24 working hours to kickstart your app's success journey.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech{" "}
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
