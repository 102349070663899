import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function Enterprise_Retail_Manufacturing() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                ADVANCED TECHNOLOGY-OPERATED
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                Enterprise, Retail & Manufacturing
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Empowering companies with our digital accelerators for the process and discrete manufacturing
                  <br />
                  sector to prepare for the future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container rSecStyle"
        data-aos="fade-up"
      >
        <div className="row gy-4 wrapper">
          <div className="col-lg-9">
            <h1>
            Our Retail & Manufacturing Service Capabilities 
            </h1>
            <article className="mt-4">
              <img
                src="/assets/images/solutions/internet-of-things.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Internet Of Things (IoT): </a>
              </h2>
              <p>
              Enabling real-time asset monitoring, predictive maintenance, connected operational intelligence, and ensuring worker safety with wearable technology.
              </p>
              <img
                src="/assets/images/solutions/enterprise-mobility.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Enterprise Mobility: </a>
              </h2>
              <p>
              Facilitating asset management, automated warehousing, container management, resource monitoring, and additional functionalities.
              </p>
              <img
                src="/assets/images/solutions/product-lifecycle-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Product Lifecycle Management (PLM): </a>
              </h2>
              <p>
              PLM deployment, integration, infrastructure solutions, support and testing, as well as upgrade and migration services.
              </p>
              <img
                src="/assets/images/solutions/supply-chain-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Supply Chain Management (SCM): </a>
              </h2>
              <p>
              Streamlined supply chain planning and optimization, design enhancement, and sales and network planning integration.
              </p>
              <img
                src="/assets/images/solutions/transportation-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Transportation Management: </a>
              </h2>
              <p>
              Freight accounting software, multi-enterprise application suite, enhanced visibility, tracking and performance monitoring, fleet integration capabilities, and a range of other functionalities.
              </p>
              <img
                src="/assets/images/solutions/enterprise-asset-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Enterprise Asset Management: </a>
              </h2>
              <p>
              Management of hardware warranties, instant inventory alerts, AI-driven analytics, and deployment via SaaS cloud technology.
              </p>
              <img
                src="/assets/images/solutions/sales-and-marketing-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Sales and Marketing Management: </a>
              </h2>
              <p>
              Data Science - Customer Analysis, Market Intelligence, Marketing Data Warehouses, Omni-channel Revolution.
              </p>
              <img
                src="/assets/images/solutions/inventory-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Inventory Management: </a>
              </h2>
              <p>
              Inventory streamlining, Stock replenishment automation, demand prediction, Barcode & RFID implementation, and reporting & analytics functionalities.
              </p>
            </article>
          </div>
          <aside
            className="col-lg-3"
          >
            <div className="servSecImg">
            <img
              src="/assets/images/solutions/enterprise-retail-&-manufacturing.jpg"
              alt=""
            />
            </div>
            <div
              className="footer-widget lineStyle mt-5"
            >
              <div className="ltpColor mx-4">
                <h3>Solutions</h3>
              </div>
              <ul>
                <li>
                  <Link to="/solutions/on-demand-delivery">On Demand Delivery</Link>
                </li>
                <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li>
                <li>
                  <Link to="/solutions/transport-logistics">Transport Logistics</Link>
                </li>
                <li>
                <Link to="/solutions/education-eLearning">Education & eLearning</Link>
                </li>
                {/* <li>
                  <Link to="/solutions/enterprise-retail-manufacturing">Enterprise Retail & Manufacturing</Link>
                </li> */}
                <li>
                  <Link to="/solutions/travel-hospitality">Travel & Hospitality</Link>
                </li>
                <li>
                  <Link to="/solutions/media-entertainment">Media & Entertainment</Link>
                </li>
                <li> <Link to="/solutions/social-networking">Social Networking</Link>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the key benefits of implementing enterprise solutions in the IT sector for businesses?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Enterprise solutions streamline operations, enhance productivity, and improve decision-making processes by integrating various business functions such as finance, HR, and supply chain management. They also provide scalability, security, and flexibility, enabling businesses to adapt to changing market demands effectively.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How can retail businesses leverage IT solutions to optimize customer experiences and increase sales?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Retailers can utilize IT solutions such as point-of-sale systems, inventory management software, and customer relationship management (CRM) tools to personalize interactions with customers, optimize inventory levels, and offer seamless omnichannel experiences. These solutions also enable data-driven insights for targeted marketing campaigns and product recommendations.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What role does IT play in transforming manufacturing processes and driving operational efficiency?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    IT solutions in the manufacturing sector facilitate automation, predictive maintenance, and real-time monitoring of production processes. By implementing technologies like Internet of Things (IoT), artificial intelligence (AI), and digital twins, manufacturers can optimize resource utilization, reduce downtime, improve quality control, and accelerate time-to-market for products. Additionally, advanced analytics and data visualization tools empower decision-makers to identify optimization opportunities and drive continuous improvement initiatives.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech{" "}
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
