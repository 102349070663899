import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function Travel_Hospitality() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">TRANSFORMING BRANDS</p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                  Travel & Hospitality Solutions
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Furnishing enhanced travel and hotel management experiences
                  through our bespoke
                  <br />
                  digital transformation solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container rSecStyle" data-aos="fade-up">
        <div className="row gy-4 wrapper">
          <div className="col-lg-9">
            <h1>Our Travel & Hospitality Solutions App Service Offerings</h1>
            <article className="mt-4">
              <img
                src="/assets/images/solutions/hotel-management-software.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Hotel Management Software: </a>
              </h2>
              <p>
                We provide solutions that streamline operations, enhance human
                resource management, and guarantee a seamless customer
                experience.
              </p>
              <img
                src="/assets/images/solutions/booking-management-system.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Booking Management System: </a>
              </h2>
              <p>
                We develop ticket and hotel booking management systems enhanced
                with cutting-edge technologies to create an engaging and
                interactive experience throughout.
              </p>
              <img
                src="/assets/images/solutions/travel-portal-development.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Travel Portal Development: </a>
              </h2>
              <p>
                At Link Tech, we specialize in creating complete travel
                solutions, incorporating OTAs' and tour operators' websites to
                oversee scheduling, payment processing, CRM, and additional
                functionalities within travel solutions.
              </p>
              <img
                src="/assets/images/solutions/gds-api-integration.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">GDS API Integration: </a>
              </h2>
              <p>
                Our team of industry specialists is adept at crafting intuitive
                architectures tailored to seamlessly integrate with Global
                Distribution System (GDS) APIs, granting customers access to a
                vast selection of hotels, tours, flights, and other services.
              </p>
              <img
                src="/assets/images/solutions/crm-solutions-hotels.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">CRM Solutions for Hotels: </a>
              </h2>
              <p>
                Our travel and hospitality development professionals implement
                personalized CRM solutions for hotels, enabling them to monitor
                the customer lifecycle, manage loyalty programs, streamline
                workflow processes, conduct data analysis, gather guest
                feedback, and perform additional functions.
              </p>
              <img
                src="/assets/images/solutions/payment-system-management.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Payment System Management: </a>
              </h2>
              <p>
                We develop tailored payment management solutions including
                bill/invoice generation, transaction monitoring, and real-time
                personalized payment options.
              </p>
            </article>
          </div>
          <aside className="col-lg-3">
          <div className="servSecImg">
            <img
              src="/assets/images/solutions/travel-&-hospitality-solutions.jpg"
              alt=""
            />
          </div>
            <div className="footer-widget lineStyle mt-5">
              <div className="ltpColor mx-4">
                <h3>Solutions</h3>
              </div>
              <ul>
                <li>
                  <Link to="/solutions/on-demand-delivery">
                    On Demand Delivery
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li>
                <li>
                  <Link to="/solutions/transport-logistics">
                    Transport Logistics
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/education-eLearning">
                    Education & eLearning
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/enterprise-retail-manufacturing">
                    Enterprise Retail & Manufacturing
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/travel-hospitality">
                    Travel & Hospitality
                  </Link>
                </li>
                {/* <li>
                  <Link to="/solutions/media-entertainment">Media & Entertainment</Link>
                </li> */}
                <li>
                  {" "}
                  <Link to="/solutions/social-networking">
                    Social Networking
                  </Link>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Why do I need to build a mobile app for my travel &
                      hospitality business?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      Expanding your business and reaching a global audience
                      necessitates the development of a mobile application,
                      which not only facilitates achieving your growth targets
                      but also automates your business processes, ultimately
                      delivering an enriched experience for your customers.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Why should I choose Link Tech for my travel & hospitality
                      app development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      At Link Tech, our dedicated team of specialists excels in
                      crafting innovative travel and hospitality solutions
                      designed to captivate and satisfy users. We offer
                      comprehensive development services for all major
                      platforms, including Android and iOS, adhering to a
                      meticulous development approach and well-structured
                      process tailored to fulfill your business requirements.
                      <br />
                      This includes:
                      <ul>
                        <li>Project Consultation</li>
                        <li>Market Study & Analysis</li>
                        <li>UI/UX Designing</li>
                        <li>Project Development</li>
                        <li>Quality Assurance</li>
                        <li>Deployment</li>
                        <li>Marketing & Maintenance</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Can I have my travel & hospitality mobile app modified after it is deployed?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Our proficient developers are ready to assist you in achieving your goals. By hiring our experts and providing them with your requirements, you'll receive updates on the timeframe, resource allocation, technologies utilized, and other pertinent details. Armed with this information, you can make informed decisions to elevate your travel and hospitality business app to an advanced level.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech{" "}
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
