import React, { useEffect } from "react";
import { useCountUp } from "react-countup";
import ReactOwlCarousel from "react-owl-carousel";

export default function About() {
  const particle = React.useRef(null);

  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  //number counting
  useCountUp({
    ref: "counter",
    end: 7,
    enableScrollSpy: true,
    scrollSpyDelay: 100,
  });
  useCountUp({
    ref: "counter1",
    end: 5,
    enableScrollSpy: true,
    scrollSpyDelay: 100,
  });
  useCountUp({
    ref: "counter2",
    end: 5,
    enableScrollSpy: true,
    scrollSpyDelay: 100,
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-10 text-center">
                <h2 data-aos="fade-up">About Us</h2>
                <p data-aos="fade-up" data-aos-delay="400">
                  We deliver outstanding digital experiences through our most
                  innovative ideas, aiming to enhance the world for all.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="recent-posts" className="sections-bg">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            {/* <!-- End post list item --> */}
            <div className="col-lg-6">
              <div className="servSecImg">
                <img
                  style={{ width: "100%" }}
                  src="/assets/images/about/about-hero.jpg"
                  alt=""
                ></img>
              </div>
            </div>
            <div style={{ paddingLeft: "50px" }} className="col-lg-6">
              <h4 className="ltpColor">About Link Tech</h4>
              <h1>Your Long Term IT Partner</h1>
              <p>
                Link Tech is a Top Web and App Development Company in Saudia
                Arabia. We work on different CMS and Frameworks like Laravel,
                WordPress, CodeIgniter etc and at the same time, we work on
                Android Apps, IOS Apps, Flutter, Nodejs, Reactjs, Angularjs,
                VueJS, etc. Hire Top Web & App Experts to cover all your Web and
                App Development requirements.
              </p>
              <div
                style={{ borderLeft: "5px solid #f2c76e", paddingLeft: "5px" }}
                className="footer-widget"
              >
                <div className="mx-4">
                  <strong>
                    <i>
                      As a leading firm in web and mobile app development, our
                      team of proficient professionals and programmers delivers
                      exceptional services in web and app development.
                    </i>
                  </strong>
                  <p>
                    We firmly believe in cultivating strong relationships with
                    our clients and remain committed to supporting them through
                    every aspect of their business journey, both in times of
                    success and challenges.
                  </p>
                </div>
              </div>
              <ul
                style={{
                  listStyleImage:
                    "url(/assets/images/about/about-right-icon.png)",
                }}
              >
                <li>High-Quality Web & App Development Solutions.</li>
                <li>
                  Highly proficient team with outstanding communication skills.
                </li>
                <li>
                  Delivery in accordance with requirements and client concepts.
                </li>
              </ul>
            </div>
            {/* <!-- End post list item --> */}
            <article className="mt-5">
              <img
                src="/assets/images/about/our-core-&-values.png"
                alt=""
              ></img>
              <h2 className="title">
                <a href="#">Our Core Values</a>
              </h2>
              <h5>Make the content a list instead of paragraph.</h5>
              <ul>
                <li>
                  <strong>Innovation: </strong>
                  Constantly seeking new and creative solutions to problems,
                  embracing change, and staying ahead of technological
                  advancements.
                </li>
                <li>
                  <strong>Excellence in work: </strong>
                  Through our dedication to excellence, we empower businesses
                  and elevate customer experiences, ensuring unparalleled
                  satisfaction in everything we undertake.
                </li>
                <li>
                  <strong>Continuous learning: </strong>
                  Through our commitment to continuous learning, we empower our
                  team to stay ahead of the curve, drive innovation, and deliver
                  exceptional results for our clients.
                </li>
                <li>
                  <strong>Transparency: </strong>
                  Embracing transparency is not just a value; it's our guiding
                  principle, fostering an environment where openness and
                  accountability thrive, ensuring the success of our projects
                  and the satisfaction of our clients.
                </li>
                <li>
                  <strong>Digital Empowerment: </strong>
                  Enabling individuals and businesses to harness the power of
                  technology for positive transformation, promoting digital
                  literacy and accessibility.
                </li>
                <li>
                  <strong>Client Satisfaction: </strong>
                  We strive for client satisfaction as we believe in keeping our
                  client's happy through our hard work.
                </li>
              </ul>
              <img
                src="/assets/images/about/mission-statement.png"
                alt=""
                className="mt-5"
              ></img>
              <h2 className="title">
                <a href="#">Mission Statement</a>
              </h2>
              <h5>
                Customized applications and services crafted to meet the
                specific demands of our clients.
              </h5>
              <ul>
                <li>
                  Excellence while delivering IT solutions to our esteemed
                  clients.
                </li>
                <li>
                  Utilizing cost-efficient approaches to effectively manage
                  budgetary concerns.
                </li>
                <li>
                  On-time delivery of products/services is what differentiates
                  us.
                </li>
                <li>
                  Our live support system enables clients to make alterations as
                  needed in real-time.
                </li>
                <li>
                  Project remains confidential and foolproof ensuring high-level
                  privacy.
                </li>
                <li>
                  Our friendly assistance ensures seamless communication and
                  removes any barriers between our team and clients.
                </li>
              </ul>
              <img
                src="/assets/images/about/vision-statement.png"
                alt=""
                className="mt-5"
              ></img>
              <h2 className="title">
                <a href="#">Vision Statement</a>
              </h2>
              {/* <h5>
                Customized applications and services crafted to meet the
                specific demands of our clients.
              </h5> */}
              <ul>
                <li>
                  Delivering exceptional services that foster remarkable growth
                  and success.
                </li>
                <li>
                  Offering IT services that are both cost-effective and highly
                  efficient.
                </li>
                <li>In a continuous pursuit of customer satisfaction.</li>
                <li>
                  Providing dependable and resilient services/products to meet
                  our clients' needs.
                </li>
                <li>
                  Delivering innovative IT solutions to tackle unique challenges
                  head-on.
                </li>
                <li>
                  Employing forward-thinking professionals who deliver
                  exceptional results by thinking outside the box.
                </li>
              </ul>
            </article>
          </div>
          {/* <!-- End recent posts list --> */}
        </div>
      </section>
      <section
        id="stats-counter"
        className="call-to-action stats-counter section"
        style={{ padding: "100px" }}
      >
        <div className="container" data-aos="fade-up">
          <div className="row gy-4 text-center">
            <div className="col-lg-12">
              <h3>Your Full-Cycle Software Development Partner.</h3>
              <p>Enriching Lives Through Our Technological Innovations.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="testimonials" className="testimonials">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Testimonials</h2>
            <p>
              Discover the voices of our satisfied clients, echoing the
              excellence of our services and the impact of our solutions.
            </p>
          </div>
          <div
            className="slides-3 swiper"
            data-aos="fade-up"
            data-aos-delay="800"
          >
            <div className="swiper-wrapper">
              <ReactOwlCarousel
                className="owl-theme"
                loop
                autoplay={true}
                responsive={{
                  0: { items: 1 },
                  768: { items: 2 },
                }}
              >
                <div className="swiper-slide">
                  <div className="testimonial-wrap">
                    <div className="testimonial-item">
                      <div className="d-flex align-items-center info-box">
                        <img
                          src="/assets/images/testimonials/testimonial-1.jpg"
                          className="testimonial-img flex-shrink-0"
                          alt=""
                        />
                        <div>
                          <h3>Aqdam</h3>
                          {/* <h4>CFO</h4> */}
                          <div className="stars">
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                          </div>
                        </div>
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        The professionalism and technical skills of the Link
                        Tech team are commendable. They delivered our project on
                        time and within budget.
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-wrap">
                    <div className="testimonial-item">
                      <div className="d-flex align-items-center info-box">
                        <img
                          src="/assets/images/testimonials/testimonial-2.jpg"
                          className="testimonial-img flex-shrink-0"
                          alt=""
                        />
                        <div>
                          <h3>The mosque services</h3>
                          {/* <h4>Web Designer</h4> */}
                          <div className="stars">
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                          </div>
                        </div>
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        Link Tech's software development services are top-notch.
                        Their team is highly skilled, responsive, and easy to
                        work with.
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-wrap">
                    <div className="testimonial-item">
                      <div className="d-flex align-items-center info-box">
                        <img
                          src="/assets/images/testimonials/testimonial-3.jpg"
                          className="testimonial-img flex-shrink-0"
                          alt=""
                        />
                        <div>
                          <h3>شركة برق</h3>
                          {/* <h4>Store Owner</h4> */}
                          <div className="stars">
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                          </div>
                        </div>
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        Link Tech delivered a robust and scalable solution that
                        met all our requirements. Their support and
                        after-service are exceptional.
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial-wrap">
                    <div className="testimonial-item">
                      <div className="d-flex align-items-center info-box">
                        <img
                          src="/assets/images/testimonials/testimonial-4.jpg"
                          className="testimonial-img flex-shrink-0"
                          alt=""
                        />
                        <div>
                          <h3>Al-Otaishan</h3>
                          {/* <h4>Freelancer</h4> */}
                          <div className="stars">
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                            <i className="bi bi-star-fill"></i>
                          </div>
                        </div>
                      </div>
                      <p>
                        <i className="bi bi-quote quote-icon-left"></i>
                        Link Tech's development team is outstanding. They
                        delivered high-quality work and were always ready to go
                        the extra mile.
                        <i className="bi bi-quote quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </ReactOwlCarousel>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
      <section
        id="stats-counter"
        className="call-to-action stats-counter section"
      >
        <div className="container" data-aos="fade-up">
          <div className="row gy-4 align-items-center">
            <div className="col-lg-4">
              <div className="stats-item d-flex flex-column align-items-center">
                <div className="icon">
                  <img
                    src="/assets/images/icons/happy-clients.png"
                    alt="icon"
                  />
                </div>
                <span id="counter"></span>
                <p>
                  <span>Happy Clients</span> Clients satisfaction is our first
                  priority
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="stats-item d-flex flex-column align-items-center">
                <div className="icon">
                  <img
                    src="/assets/images/icons/complete-projects.png"
                    alt="icon"
                  />
                </div>
                <span id="counter1"></span>
                <p>
                  <span>Completed Projects</span> Successfully delivered the
                  projects on time
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="stats-item d-flex flex-column align-items-center">
                <div className="icon">
                  <img
                    src="/assets/images/icons/hours-support.png"
                    alt="icon"
                  />
                </div>
                <span id="counter2"></span>
                <p>
                  <span>Running Projects</span> Discover excellence in
                  development
                </p>
              </div>
            </div>
          </div>
          <div className="row pt-5 gy-4 text-center">
            <div className="col-lg-12 pt-5">
              <h3>Let's Discuss your Projects</h3>
              <p>
                We pride ourselves with our ability to perform and deliver
                results. Use the form below to discuss your project needs with
                our team, we will get back asap
              </p>
              <a className="cta-btn" href="mailto:info@example.com">
                Conatct Us
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
