import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function Privacy_Policy() {
  const particle = React.useRef(null);

  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  const elementRefs = {
    element1: useRef(null),
    element2: useRef(null),
    element3: useRef(null),
    element4: useRef(null),
    element5: useRef(null),
    element6: useRef(null)
  };
  // Function to scroll to the element
  const scrollToElement = (elementRef) => {
    const element = elementRef.current;
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - 170;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };
  

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-10 text-center">
                <h2 data-aos="fade-up">Company Privacy Policy</h2>
                <p data-aos="fade-up" data-aos-delay="400">
                  Thank you for joining our community at Link Tech ("Company,"
                  "we," "us," "our"). We prioritize safeguarding your personal
                  information and respecting your privacy rights. Should you
                  have any inquiries or concerns regarding this privacy notice
                  or how we handle your personal information, please don't
                  hesitate to reach out to us at <a href="mailto:support@linktech.com.sa">support@linktech.com.sa</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="recent-posts" className="sections-bg">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            {/* <!-- End post list item --> */}
            <div className="col-lg-8">
              <article>
                {/* <img
                  src="/assets/images/privacy-policy/expertise-and-experience.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img> */}
                <h1>Link Tech Company Privacy policy: </h1>
                <p className="text-justify">
                  Thank you for joining our community at Link Tech ("Company,"
                  "we," "us," "our"). We prioritize safeguarding your personal
                  information and respecting your privacy rights. Should you
                  have any inquiries or concerns regarding this privacy notice
                  or how we handle your personal information, please don't
                  hesitate to reach out to us at <a href="mailto:support@linktech.com.sa">support@linktech.com.sa</a>.<br />
                  <br />
                  When you browse our website
                  <a
                    target="_blank"
                    href="https://linktech.com.sa/"
                  >
                    {" "}
                    Link Tech{" "}
                  </a>
                  (referred to as the "Website") or utilize any of our services
                  (referred to as the "Services," encompassing the Website), we
                  acknowledge and value the trust you place in us regarding your
                  personal information. Safeguarding your privacy is of utmost
                  importance to us. Through this privacy notice, we aim to
                  elucidate in the most transparent manner possible the types of
                  information we collect, how we utilize it, and the rights you
                  possess concerning it. We encourage you to take the time to
                  thoroughly review this notice, as it holds significance. If
                  any terms within this privacy notice are disagreeable to you,
                  we kindly request that you cease using our Services
                  immediately. This privacy notice is applicable to all data
                  collected through our Services, which includes our Website,
                  along with any associated services, sales, marketing
                  activities, or events. <br /> <br />
                  Please read this privacy notice carefully as it will help you
                  understand what we do with the information that we collect.
                  <br />
                </p>
                <h4>Index of Contents:</h4>
                <ol className="text-uppercase">
                  <li>What information do we collect?</li>
                  <li>How do we use your information?</li>
                  <li>Will your information be shared with anyone?</li>
                  <li>Do we use cookies and other tracking technologies?</li>
                  <li>How long do we keep your information?</li>
                  <li>How do we keep your information safe?</li>
                  <li>Controls for do-not-track features</li>
                  <li>
                    How can you review, update or delete the data we collect
                    from you?
                  </li>
                  <li>
                    We do not include or offer third-party products or services
                    on our website.
                  </li>
                </ol>
                <img
                  src="/assets/images/privacy-policy/what-information-do.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1 ref={elementRefs.element1}>1. WHAT INFORMATION DO WE COLLECT?</h1>
                <p>
                  <strong>Personal information disclosed to us: </strong>
                  <br />
                  In summary, we gather personal information voluntarily
                  provided by you. This includes data submitted during website
                  registration, inquiries about our products and services,
                  participation in website activities, or any direct
                  communication with us. The specific personal information we
                  collect depends on your interactions with us, your
                  preferences, and the features you engage with on the website.
                  This may encompass details such as names, phone numbers, email
                  addresses, and similar identifiers.
                  <br />
                  <br />
                  <strong>Payment details:</strong>
                  <br />
                  Additionally, should you make purchases; we may collect
                  necessary payment information to facilitate transactions, such
                  as credit card numbers and associated security codes.
                  <br />
                  It is imperative that all personal information shared with us
                  remains accurate, complete, and truthful. Please inform us
                  promptly of any updates or changes to your personal details.
                </p>
                <img
                  src="/assets/images/privacy-policy/we-use-your-information.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1 ref={elementRefs.element2}>2. HOW DO WE USE YOUR INFORMATION? </h1>
                <p>
                  In summary, we utilize your information for purposes aligned
                  with legitimate business interests, fulfilling contractual
                  obligations, complying with legal mandates, and/or obtaining
                  your consent.
                  <br /> <br />
                  We employ personal information gathered through our Website
                  for a range of business objectives outlined below. We process
                  this personal information based on our legitimate business
                  interests, the necessity to enter into or execute a contract
                  with you, your explicit consent, and/or compliance with legal
                  obligations. We specify the particular processing grounds we
                  rely on alongside each purpose outlined below.
                  <br /> <br />
                  <strong>Fulfill and manage your orders. </strong>
                  We may use your information to fulfill and manage your orders,
                  payments, returns, and exchanges made through the Website.
                  <br /> <br />
                  <strong>Administer prize draws and competitions. </strong>
                  We may use your information to administer prize draws and
                  competitions when you elect to participate in our
                  competitions.
                  <br /> <br />
                  <strong>To deliver and facilitate the delivery of services to the user. </strong>
                  We may use your information to provide you with the requested
                  service. 
                  <br /> <br />
                  <strong>To address user inquiries and provide support. </strong>
                  We may utilize your information to respond to inquiries and
                  resolve any potential issues you may encounter while using our
                  Services. Sending marketing and promotional communications: We
                  and/or our third-party marketing partners may employ the
                  personal information you provide to us for marketing purposes,
                  provided it aligns with your marketing preferences. This
                  includes instances such as expressing interest in obtaining
                  information about us or our Website, subscribing to marketing
                  materials, or contacting us. You retain the option to opt out
                  of our marketing emails at any time. Delivering targeted
                  advertising: Your information enables us to create and exhibit
                  personalized content and advertisements (in collaboration with
                  third parties) tailored to your preferences and/or location,
                  while also assessing their effectiveness.
                </p>
                <img
                  src="/assets/images/privacy-policy/your-information-be-shared.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1 ref={elementRefs.element3}>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h1>
                <p>
                  In summary, we share information solely with your consent, to
                  adhere to legal requirements, to deliver services to you, to
                  safeguard your rights, or to fulfill business obligations.
                  <br /> <br />
                  <strong>
                    We may process or share your data that we hold based on the
                    following legal basis:
                  </strong>
                  <br />
                  Consent: We may process your data if you have given us
                  specific consent to use your personal information for a
                  specific purpose.
                  <br />
                  Legitimate Interests: We may handle your data when it is
                  reasonably essential to pursue our legitimate business
                  interests.
                  <br />
                  Performance of a Contract: If we have engaged in a contract
                  with you, we may utilize your personal information to fulfill
                  the obligations outlined in our agreement.
                  <br />
                  Legal Obligations: We might divulge your information when
                  legally mandated to do so to adhere to relevant laws,
                  governmental inquiries, judicial proceedings, court orders, or
                  legal procedures, such as responding to a court order or
                  subpoena (including to public authorities to fulfill national
                  security or law enforcement obligations).
                  <br />
                  Vital Interests: We may disclose your information where we
                  believe it is necessary to investigate, prevent, or take
                  action regarding potential violations of our policies,
                  suspected fraud, situations involving potential threats to the
                  safety of any person and illegal activities, or as evidence in
                  litigation in which we are involved.
                  <br />
                  <br />
                  <strong>
                    More specifically, there may be instances where we need to
                    process your data or disclose your personal information as
                    follows:
                  </strong>
                  <br />
                  Business Transfers: We might share or transfer your
                  information in relation to, or during discussions of, any
                  merger, sale of company assets, financial transaction, or
                  acquisition of our business, either wholly or partially, by
                  another company.
                </p>
                <img
                  src="/assets/images/privacy-policy/use-cookies-and-other.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1 ref={elementRefs.element4}>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h1>
                <p>
                  In Short: We may use cookies and other tracking technologies
                  to collect and store your information. <br />
                  We may use cookies and similar tracking technologies (like web
                  beacons and pixels) to access or store information.
                </p>
                <img
                  src="/assets/images/privacy-policy/keep-your-information.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1 ref={elementRefs.element5}>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h1>
                <p>
                  In summary, we retain your information for the duration
                  necessary to fulfill the objectives delineated in this privacy
                  notice, unless otherwise mandated by law. <br />
                  <br />
                  We will retain your personal information solely for the
                  duration necessary to fulfill the purposes outlined in this
                  privacy notice, unless a longer retention period is
                  necessitated or permitted by law (e.g., for tax, accounting,
                  or other legal obligations). None of the purposes outlined in
                  this notice will necessitate retaining your personal
                  information beyond the period in which users maintain an
                  account with us. <br />
                  <br />
                  Once there is no ongoing legitimate business necessity to
                  process your personal information, we will either erase or
                  anonymize the data. If deletion or anonymization is not
                  feasible (e.g., due to personal information stored in backup
                  archives), we will securely store your data and segregate it
                  from any additional processing until deletion becomes viable.
                </p>
                <img
                  src="/assets/images/privacy-policy/your-information-safe.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1 ref={elementRefs.element6}>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h1>
                <p>
                  In summary: We aim to protect your personal information
                  through a system of organizational and technical security
                  measures. <br />
                  <br />
                  We have instituted suitable technical and organizational
                  security measures to safeguard the personal information we
                  handle. However, despite our diligent efforts, no electronic
                  transmission or storage system can be entirely secure. We
                  cannot ensure or guarantee that unauthorized third parties
                  will not circumvent our security measures, potentially
                  accessing, stealing, or altering your information. While we
                  strive to protect your personal data to the best of our
                  ability, the transmission of such data to and from our Website
                  carries inherent risks, and accessing the Website should be
                  done in a secure environment.
                </p>
                <img
                  src="/assets/images/privacy-policy/do-not-track-features.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1>7. CONTROLS FOR DO-NOT-TRACK FEATURES</h1>
                <p>
                  Many web browsers and some mobile operating systems and
                  applications provide a "Do Not Track" ("DNT") feature or
                  setting that allows you to indicate your preference regarding
                  the collection of data about your online browsing activities.
                  However, no standard technology for recognizing and
                  implementing DNT signals has been universally accepted.
                  Consequently, we presently do not acknowledge DNT browser
                  signals or any other mechanism that conveys your decision not
                  to be tracked online automatically. Should a standard for
                  online tracking be established in the future, necessitating
                  our compliance, we will update you on this practice in a
                  revised version of our privacy notice.
                </p>
                <img
                  src="/assets/images/privacy-policy/update-or-delete-the-data.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1>
                  8. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </h1>
                <p>
                  In accordance with the relevant laws of your jurisdiction, you
                  may have the right to access, modify, or delete the personal
                  information we gather from you under certain circumstances. To
                  request a review, update, or deletion of your personal data,
                  please visit:{" "}
                  <a
                    href="https://linktech.com.sa/"
                    target="_blank"
                  >
                    linktech.com.sa
                  </a>{" "}
                  We commit to responding to your request within 30 days. This
                  privacy policy has been generated using Termly's Privacy
                  Policy Generator.
                </p>
                <img
                  src="/assets/images/privacy-policy/third-party-products.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h1>
                  9. We do not include or offer third-party products or services
                  on our website.
                </h1>
                <h4>Google</h4>
                <p>
                  The advertising criteria of Google are encapsulated by
                  <a
                    target="_blank"
                    href="https://support.google.com/adspolicy/answer/6008942?hl=en"
                  >
                    {" "}
                    Google's Advertising Principles.{" "}
                  </a>
                  which are implemented to enhance user experience positively.
                  Google, as a third-party vendor, employs cookies, including
                  DART cookies, to display ads on your websites based on users'
                  past visits to our sites and other sites on the internet.
                  Users have the option to opt out of this cookie policy by
                  visiting the
                  <a
                    href="https://policies.google.com/technologies/ads"
                    target="_blank"
                  >
                    {" "}
                    Google Ad and Content Network Privacy Policy.{" "}
                  </a>
                  <br />
                  Furthermore, we utilize first-party cookies, such as Google
                  Analytics cookies, and other third-party cookies, such as
                  DoubleClick cookies, along with other third-party identifiers,
                  to aggregate data concerning user interactions with ad
                  impressions and other advertising service functions relevant
                  to our website.
                </p>
              </article>
            </div>
            <div style={{ paddingLeft: "40px" }} className="col-lg-4">
              <div
                style={{ borderLeft: "5px solid #f2c76e", paddingLeft: "10px" }}
                className="footer-widget"
              >
                <div className="ltpColor mx-4">
                  <h3>Privacy Policy</h3>
                </div>
                <ul
                  className="list"
                  style={{ borderLeft: "5px solid #f2c76e" }}
                >
                  <li>
                    <a href="#" onClick={() => scrollToElement(elementRefs.element1)}>
                      WHAT INFORMATION DO WE COLLECT?
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => scrollToElement(elementRefs.element2)}>
                      HOW DO WE USE YOUR INFORMATION?
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => scrollToElement(elementRefs.element3)}>
                      WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => scrollToElement(elementRefs.element4)}>
                      DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => scrollToElement(elementRefs.element5)}>
                      HOW LONG DO WE KEEP YOUR INFORMATION?
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => scrollToElement(elementRefs.element6)}>
                      HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- End post list item --> */}
          </div>
          {/* <!-- End recent posts list --> */}
        </div>
      </section>
    </div>
  );
}
