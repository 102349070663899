// PHP Core, MySQL, Laravel. Salary Range - 50000 +
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Software_development() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                  Innovative Software Solutions Created
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                  Custom Software Development Company
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Delivering personalized software development solutions, meticulously crafted around your requirements and dedicated to
                  <br/>
                  ensuring client satisfaction, we empower businesses to thrive in their digital endeavors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="container rSecStyle" data-aos="fade-up">
          <div className="row gy-4 wrapper">
            {/* <!-- End post list item --> */}
            <div className="col-lg-9">
              <h1>Our Custom Software Development Company</h1>
              <article className="mt-4">
              <div className="servSecImg">
                <img
                  className="img-fluid"
                  src="/assets/images/services/software-development.jpg"
                  alt=""
                />
              </div>
                <img
                  src="/assets/images/services/custom-crm-icon.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Custom CRM Development</a>
                </h2>
                <p>
                  Specializing in custom CRM software development, we offer
                  comprehensive services including CRM module creation, seamless
                  migration solutions, mobile application development, database
                  management, and cloud integration, catering to a wide array of
                  industries.
                </p>
                <img
                  src="/assets/images/services/legacy-system.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Legacy System Modernization</a>
                </h2>
                <p>
                  Our team of digital modernization experts meticulously
                  assesses your business processes, identifies optimal
                  technologies, and seamlessly migrates applications to the
                  cloud, revolutionizing your IT ecosystem.
                </p>
                <img
                  src="/assets/images/services/custom-software.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Custom Software</a>
                </h2>
                <p>
                  Delivering customized software solutions, our services
                  encompass process automation, product scalability, real-time
                  analytics, and agile innovation, all tailored to elevate your
                  business operations.
                </p>
                <img
                  src="/assets/images/services/enterprise-software.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Enterprise Software</a>
                </h2>
                <p>
                  Link Tech delivers groundbreaking solutions for web, mobile,
                  and desktop platforms, spanning enterprise mobility, custom
                  developments, and digital transformation initiatives, driving
                  innovation and efficiency across your organization.
                </p>
                <img
                  src="/assets/images/services/software-product.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Software Product Development</a>
                </h2>
                <p>
                  Providing comprehensive end-to-end services, we handle
                  everything from the development and deployment of dedicated
                  software, encompassing requirement analysis, design, rigorous
                  testing, and ongoing support, ensuring a seamless and
                  successful implementation for your business.
                </p>
                <img
                  src="/assets/images/services/data-analytics.png"
                  alt=""
                  className="mt-4 mb-2"
                ></img>
                <h2 className="title">
                  <a href="#">Data Analytics & Information</a>
                </h2>
                <p>
                  Our seasoned data analysts specialize in offering
                  comprehensive data management services, modernization
                  solutions, and advanced data analysis techniques, enabling you
                  to unlock valuable insights and strategize effectively for
                  your business's growth and success.
                </p>
              </article>
            </div>
            <aside
              className="col-lg-3"
            >
              <div className="servSecImg">
              <img
                src="/assets/images/services/software-development-small.jpg"
                alt=""
              />
              </div>
              <div
                className="footer-widget lineStyle mt-5"
              >
                <div className="ltpColor mx-4">
                  <h3>Services</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/services/app-development">
                      Mobile App Developemnt
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/web-development">Web Developemnt</Link>
                  </li>
                  {/* <li>
                    <Link to="/services/software-development">
                      Software Developemnt
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/services/it-consulting">IT Consulting</Link>
                  </li>
                  <li>
                    <Link to="/services/digital-marketing">
                      Digital Marketing
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">Cloud Services</Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/cloud-services">
                      Up Comming Services
                    </Link>
                  </li>
                </ul>
              </div>
            </aside>
            {/* <!-- End post list item --> */}
          </div>
          {/* <!-- End recent posts list --> */}
        </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Why should I choose custom Software development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      The primary rationale for choosing custom software
                      development lies in the ownership of the final product,
                      encompassing its data, codes, and functionalities
                      post-development and deployment. Additionally, custom
                      software offers exceptional flexibility, enabling seamless
                      upgrades or scalability with supported functionalities and
                      technologies, providing tailored solutions to evolving
                      business needs.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What is the detailed process of custom software
                      development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      We design and deliver an excellent quality web app product
                      to our client's requirements. We are effectively
                      delivering multiple services ranging from:
                      <ul>
                        <li>Idea Generation</li>
                        <li>Planning & Research</li>
                        <li>Wireframing & Prototyping</li>
                        <li>Development</li>
                        <li>Testing</li>
                        <li>Deployment</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How to succeed with custom software development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Prior to engaging a development company, it's imperative to outline your software requirements meticulously. Furthermore, conduct comprehensive interviews to ascertain whether their skill set aligns with the intricacies of your project, ensuring a seamless development process tailored to your needs.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-4"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What is the different between customize and tailored software development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    There is no such difference between customized software development and tailored or bespoke software development, all are the same.
                    </div>
                  </div>
                </div>
                {/* <!-- # Faq item--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>                        
                        <div className="col-md-6">
                          <h5>By sending this form I agree to the Link Tech <Link to="/privacy-policy"> Privacy Policy</Link></h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
