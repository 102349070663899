import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer id="footer" className="footer-section">
        <div className="container">
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to="/" className="logo d-flex align-items-center">
                      <img src="assets/images/logo.png" alt="logo" />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>
                      Link Tech offers innovative IT solutions in diverse IT
                      fields across Saudi Arabia. Our software solutions
                      streamline your business, enhance productivity, and drive
                      success with cutting-edge technology. Trust us to bridge
                      your ideas.
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="#" className="twitter">
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a href="#" className="facebook">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="#" className="instagram">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="#" className="linkedin">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Services</h3>
                  </div>
                  <ul className="list">
                    <li>
                      <Link to="/services/app-development">
                        Mobile App Developemnt
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/web-development">
                        Web Developemnt
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/software-development">
                        Software Developemnt
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/it-consulting">IT Consulting</Link>
                    </li>
                    <li>
                      <Link to="/services/digital-marketing">
                        Digital Marketing
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/cloud-services">Cloud Services</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Information</h3>
                  </div>
                  <ul className="list">
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/why-choose-us">Why Us</Link>
                    </li>
                    <li>
                      <Link to="/teams">Teams</Link>
                    </li>
                    <li>
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">Terms &amp; Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Portfolio</Link>
                    </li>
                    {/* <li>
                      <Link to="/testimonials">Testimonials</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="contact-widget footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Contacts</h3>
                  </div>
                  <div className="footer-text">
                    <p>
                      <i className="bi bi-geo-alt-fill mr-15"></i>{" "}
                      <Link
                        to="https://maps.app.goo.gl/T7ern4bqWb53UNfG9"
                        target="_blank"
                      >
                        Makkah Al Rusifa, Saudi Arabia
                      </Link>
                    </p>
                    <p>
                      <i className="bi bi-telephone-inbound-fill mr-15"></i>{" "}
                      <Link to="tel:+966-569777712">+966 569777712</Link>
                    </p>
                    <p>
                      <i className="bi bi-envelope-fill mr-15"></i>{" "}
                      <a
                        className="cta-btn"
                        href="mailto:support@linktech.com.sa"
                      >
                        support@linktech.com.sa
                      </a>
                    </p>
                  </div>
                </div>
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Newsletter</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Don't miss to subscribe to our new feeds, kindly fill up
                      your email.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <form action="#">
                      <input type="text" placeholder="Email Address" />
                      <button>
                        <i className="bi bi-telegram"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-left text-lg-left">
                <div className="copyright-text">
                  <p>
                    LinkTech<span>.</span> © 2023 - Designed by{" "}
                    <a target="_blank" href="https://linktech.com.sa/">Link Tech</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
