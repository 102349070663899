import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function Banking_Finance_Insurance() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                Elevate banking into the digital era.
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                Banking, Finance & Insurance
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Unlock competitive advantages with our tailored solutions for Banking, Finance, and Insurance (BFSI) industries,
                  <br />
                  ensuring superior performance and security.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container rSecStyle"
        data-aos="fade-up"
      >
        <div className="row gy-4 wrapper">
          <div className="col-lg-9">
            <h1>
            Banking, Finance & Insurance Solutions Service offerings
            </h1>
            <article className="mt-4">
              <img
                src="/assets/images/solutions/mobile-banking.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Mobile banking: </a>
              </h2>
              <p>
              Developing user-centric solutions tailored to the intricacies of banking operations, we enable customers to easily access services, ultimately saving them valuable time. 
              </p>
              <img
                src="/assets/images/solutions/diet-&-nutrition.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Finance Management: </a>
              </h2>
              <p>
                Experience tailored solutions that put financial management at your fingertips, providing real-time transaction tracking and unparalleled control over your finances. 
              </p>
              <img
                src="/assets/images/solutions/accounting-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Accounting App: </a>
              </h2>
              <p>
              Tailored to your needs, our solutions are designed to efficiently manage day-to-day transactions, encompassing cash and asset management, invoicing, tax preparation, savings, and more, ensuring seamless financial operations. 
              </p>
              <img
                src="/assets/images/solutions/insurance-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Insurance App: </a>
              </h2>
              <p>
              Link Tech is dedicated to developing versatile insurance web and mobile app solutions, providing effortless CRM management through an intuitive platform tailored to your needs. 
              </p>
              <img
                src="/assets/images/solutions/mobile-wallet.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Mobile Wallet: </a>
              </h2>
              <p>
              Our team of developers specializes in crafting tailored mobile wallet solutions for websites and applications, facilitating seamless access to payment gateways and elevating user experience to new heights. 
              </p>              
            </article>
          </div>
          <aside
            className="col-lg-3"
          >
            <div className="servSecImg">
            <img
              src="/assets/images/solutions/banking-finance-&-insurance.jpg"
              alt=""
            />
            </div>
            <div
              className="footer-widget lineStyle mt-5"
            >
              <div className="ltpColor mx-4">
                <h3>Solutions</h3>
              </div>
              <ul>
                <li>
                  <Link to="/solutions/healthcare-lifesciences">Healthcare & Lifesciences</Link>
                </li>
                {/* <li>
                  <Link to="/solutions/banking-finance-insurance">Banking Finance & Insurance</Link>
                </li> */}
                <li>
                  <Link to="/solutions/e-commerce-solutions"> E-Commerce Solutions </Link>
                </li>
                <li>
                  <Link to="/solutions/on-demand-delivery">On Demand Delivery</Link>
                </li>
                <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li>
                <li>
                  <Link to="/solutions/transport-logistics">Transport Logistics</Link>
                </li>
                <li>
                    <Link to="/solutions/education-eLearning">Education & eLearning</Link>
                  </li>
                  <li>
                    <Link to="/solutions/enterprise-retail-manufacturing">Enterprise Retail & Manufacturing</Link>
                  </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Why need to build Banking, Finance & Insurance Software?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Our Banking, Finance Services, and Insurance solutions are meticulously crafted to integrate system automation, facilitating increased organizational productivity by empowering employees to enhance their efficiency and streamlining financial operations through automation.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the benefits of developing a high-performing Banking, Finance & Insurance App?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    The banking, finance, and insurance app is designed to elevate performance through advanced features, enhancing user engagement and delivering a sophisticated user experience. These BFSI applications offer insightful information, coupled with enhanced performance, compatibility across browsers, responsiveness to various screen sizes, effortless installation, and more, ensuring optimal usability and satisfaction for end-users.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      How do you build secure Banking & Finances Software?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                      <p>
                      Drawing from decades of learning and experience, we specialize in developing technologically advanced and highly secure custom web and app solutions tailored for diverse financial service providers.
                      </p>
                      <p>
                      To fortify security measures, we harness cutting-edge technologies such as Machine Learning, Artificial Intelligence, Blockchain, Cyber Security, Big Data, and more. By leveraging these innovative tools, we ensure that all transactions are meticulously secured and encrypted, upholding the highest standards of safety and trust for our clients and their users.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech{" "}
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                          {/* <button type="submit" data-text="Send Message">
                            Send Message
                          </button> */}
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
