import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../LTStyles.css";

export default function Social_Networking() {
  const particle = React.useRef(null);
  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">
                AMPLIFYING CONNECTIONS
                </p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                Social Networking App Development
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Fueling conversations and fostering connections through our interactive and user-friendly Social Networking
                  <br />
                  mobile app development services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container rSecStyle" data-aos="fade-up">
        <div className="row gy-4 wrapper">
          <div className="col-lg-9">
            <h1>Our Social Networking Development Service Offerings</h1>
            <article className="mt-4">
              <img
                src="/assets/images/solutions/custom-social-networking-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Custom Social Networking App: </a>
              </h2>
              <p>
              Our skilled and exceptionally efficient developers specialize in crafting tailor-made social networking applications integrated with cutting-edge features to seamlessly bring your unique ideas to fruition.
              </p>
              <img
                src="/assets/images/solutions/messaging-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Messaging App: </a>
              </h2>
              <p>
              We develop intuitive, secure, and scalable messaging applications for your business or organization, accommodating various multimedia formats including text, images, and video, while ensuring user-friendliness.
              </p>
              <img
                src="/assets/images/solutions/forum-blogs-publishing-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Forum, Blogs & Publishing App: </a>
              </h2>
              <p>
              Our proficient developers craft cutting-edge forums, publishing, and blogging platforms loaded with rich features and trending capabilities, enhanced by moderation tools and a seamless user interface.
              </p>
              <img
                src="/assets/images/solutions/social-game-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Social Game App: </a>
              </h2>
              <p>
              At Link Tech, our social networking app development team excels in creating immersive and user-friendly social games, fostering connections among friends and the community through engaging experiences.
              </p>
              <img
                src="/assets/images/solutions/social-network-analysis-tool.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Social Network Analysis Tool: </a>
              </h2>
              <p>
              At Link Tech, we specialize in developing intuitive tools integrated with advanced algorithms and data analysis techniques, enabling you to derive valuable insights and facilitate accurate decision-making processes.
              </p>
              <img
                src="/assets/images/solutions/video-based-app.png"
                alt=""
                className="mt-4 mb-2"
              ></img>
              <h2 className="title">
                <a href="#">Video-Based App: </a>
              </h2>
              <p>
              We specialize in building custom applications designed to meet your precise specifications, facilitating seamless video sharing and viewing experiences while seamlessly integrating live streaming capabilities to deliver an immersive and unique user experience.
              </p>
            </article>
          </div>
          <aside className="col-lg-3">
          <div className="servSecImg">
            <img src="/assets/images/solutions/social-networking-app-development.jpg" alt="" />
          </div>
            <div className="footer-widget lineStyle mt-5">
              <div className="ltpColor mx-4">
                <h3>Solutions</h3>
              </div>
              <ul>
                <li>
                  <Link to="/solutions/on-demand-delivery">
                    On Demand Delivery
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li>
                <li>
                  <Link to="/solutions/transport-logistics">
                    Transport Logistics
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/education-eLearning">
                    Education & eLearning
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/enterprise-retail-manufacturing">
                    Enterprise Retail & Manufacturing
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/travel-hospitality">
                    Travel & Hospitality
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/media-entertainment">
                    Media & Entertainment
                  </Link>
                </li>
                {/* <li> <Link to="/solutions/social-networking">Social Networking</Link>
                </li> */}
              </ul>
            </div>
          </aside>
        </div>
      </div>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row gy-4">
            <div className="col-lg-12">
              <div
                className="accordion accordion-flush"
                id="faqlist"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      Why should I prefer Social Networking App Development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Boost your brand's visibility, foster increased user interaction, facilitate dynamic communication, pinpoint your target audience, and forge deeper connections with your customers through our tailored social networking app development solutions.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      What are the latest tools & technologies you use for Social Networking App Development?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    The advanced tools & technologies that our Social Networking App Development team uses are Java, PHP, Python, JavaScript, Angular.js, Swift, and other platform-dependent technologies. <br/>
                    Our development team ensures that you get the aptest solution for the defined idea and be your reliable social networking app development services using the latest technologies. 
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      <span className="num">
                        <i className="bi bi-arrow-right-circle-fill"></i>
                      </span>
                      I have a website can you help me boost social media engagement?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className="accordion-body">
                    Elevate your digital presence with our extensive suite of services, encompassing customer engagement initiatives, bespoke digital ad creation, top-tier PPC management, strategic digital research, and impactful influencer marketing campaigns. Whatever your niche, trust us to craft a personalized digital strategy that maximizes user engagement and drives results for your brand.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="contact" className="contact-section section">
        <div className="section-header">
          <h2>Let's Talk</h2>
          <p>Do you have a project in mind? Talk to the experts.</p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
              <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Address:</h6>
                        <p>Makkah Al Rusifa, Saudi Arabia</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Phone:</h6>
                        <p>+966 569777712</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Email:</h6>
                        <p>support@linktech.com.sa</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    id="ajax-contact"
                    method="post"
                    action="assets/phpscripts/contact.php"
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required="required"
                              data-error="Name is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required="required"
                              data-error="Valid email is required."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              placeholder="Write Your Message*"
                              required="required"
                              data-error="Please, leave us a message."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" data-text="Send Message">
                            Send Message
                          </button>
                        </div>
                        <div className="col-md-6">
                          <h5>
                            By sending this form I agree to the Link Tech
                            <Link to="/privacy-policy"> Privacy Policy</Link>
                          </h5>
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-success alert-dismissable alert-dismissable hidden"
                            id="msgSubmit"
                          >
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                            Thank You! your message has been sent.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
